import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faExclamationTriangle, faMoneyBill1Wave } from "@fortawesome/free-solid-svg-icons";
import { getEstado } from "../../../../../../../shared/helpers/functionalities";
import { Link } from "react-router-dom";

export default function IncidenciaInfo({
    incidencia,
    cliente,
    onGoToEnvio,
    onViewValeDescuento,
    onViewAvisos
}) {

    return (
        <div className="w-100 d-flex flex-wrap align-items-stretch justify-content-between">
            <div className="d-flex align-items-start">
                <div className="d-flex flex-column me-3">
                    <span className="icon">
                        <FontAwesomeIcon icon={faExclamationTriangle} size="1x" color="#215732" />
                    </span>
                </div>

                
                <div className="d-flex flex-column me-3">
                    <div className="d-flex flex-column">
                        <div className="d-flex text-uppercase">
                            <h3 className="text-success me-2">
                                {incidencia?.numero}
                            </h3>
                            <p className={(incidencia?.estado_incidencia_id === 1 ? 'text-danger' : 'text-green')}>
                                <b>{incidencia?.estado?.nombre}</b>
                            </p>
                        </div>
                        
                        <p>
                            <Link target="_blank" rel="noopener noreferrer" to={"/presupuesto/" + incidencia?.presupuesto.id} title="Ficha presupuesto" className="text-green fw-bold">
                                {incidencia?.presupuesto?.numero}
                            </Link>
                        </p>

                        <div className="d-flex align-items-center mb-1">
                        { incidencia?.envios?.map(envio => {
                            return (
                                <div className="">
                                    <span onClick={() => onGoToEnvio(envio)} className="link">
                                        {envio?.codigo_transporte}
                                    </span>

                                    <div className="w-100 d-flex align-items-center justify-content-end">
                                        <p>{envio?.codigo_envio}</p>
                                        <p className={"fw-bold ms-3 " + getEstado(envio?.transportista_id, envio?.estado?.codigo)}>
                                            {envio?.estado?.nombre}
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column text-uppercase">
                    <div className="d-flex flex-column">
                        <p>
                            Culpa: <span className="fw-bold text-uppercase">{incidencia?.culpa.replace('-', ' ')}</span>
                        </p>

                        <p>
                            Tipo: <span className="fw-bold">{incidencia?.tipo_incidencia?.nombre}</span>
                        </p>

                        {incidencia?.subtipo_incidencia?.nombre &&
                            <p>
                                Subtipo: <span className="fw-bold">{incidencia?.subtipo_incidencia?.nombre}</span>
                            </p>
                        }

                        <p>Pago con: &nbsp;
                            <span className="fw-bold text-uppercase text-green"> 
                                {incidencia?.venta?.tipo_pago_id === 1 ? 'Tarjeta de crédito' : (incidencia?.venta?.tipo_pago_id === 2) ? 'Bizum' : 'A crédito'}
                            </span>
                        </p>

                        <div className="d-flex align-items-center">
                            <p>Bolsa disponible: &nbsp;
                                <span className="fw-bold text-green">{cliente.bolsa_disponible}€</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="">
               
            </div>
            
            <div className="d-flex align-items-center">
                <button className="btn btn-add me-2"  onClick={() => onViewValeDescuento()}>
                    <span className="add">
                        <FontAwesomeIcon icon={faMoneyBill1Wave} size="1x" className="action" color="#215732" /> 
                    </span>
                    Vale 
                </button>
            
                <button className="btn btn-add"  onClick={() => onViewAvisos()}>
                    <span className="add">
                        <FontAwesomeIcon icon={faExclamationCircle} size="1x" className="action" color="#215732" /> 
                    </span>
                    Avisos 
                </button>
            </div>
        </div>
    )
}