import React, { createRef } from "react";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { faCogs } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { newDevolucion } from "../../../shared/services/devoluciones";
import { getVentaCompleta } from "../../../shared/services/ventas";
import ConfirmModal from "../../../shared/components/ConfirmModal";
import { getEstado, setErrorMessage } from "../../../shared/helpers/functionalities";
import { useDispatch } from "react-redux";

export default function DevolucionWizard({ventaId}) {
    const dispatch = useDispatch();
    const submitBtn = createRef();
    const navigate = useNavigate();
    const { register, handleSubmit, watch, getValues } = useForm();
    const [venta, setVenta] = useState({});
    const [refoundType, setRefoundType] = useState(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [isSelectedRefoundType, setIsSelectedRefoundType] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const ventaResult = await getVentaCompleta(ventaId);
            if(ventaResult.data) {
                dispatch({type: 'loading/set', payload: false});
                setVenta(ventaResult.data);
            }
        };

        dispatch({type: 'loading/set', payload: true});
        fetchData();
     }, []);

    const handleNewDevolucion = (value) => {
        setRefoundType(value);
        setIsSelectedRefoundType(true);

        if(value === 2) {
            setIsDisabled(true);
        }
    }

    const handleOpenModal = () => setShowConfirmModal(true);
    const handleConfirmDevolucion = (confirm) => {
        if(confirm) {
            if(refoundType === 2) onSubmitDevolucionTotal();
            else submitBtn.current.click();
        }
        else setShowConfirmModal(false);
    }

    const onSubmitDevolucionTotal = async () => {
        const devolucionPiezas = [];
        let envio;

        if(venta.envios && venta.envios?.length > 0) {
            envio = venta.envios.reduce((acc, cur) => acc = acc + (parseFloat(cur.precio)), 0);
        } else {
            envio = venta.piezas.reduce((acc, cur) => acc = acc + (parseFloat(cur.precio_transporte)), 0);
        }

        venta.piezas?.forEach(pieza => {
            let envioPieza;
            const cancelarEnvio = getValues('cancelar-envio_' + pieza.id);
            const envioDevolucion = getValues('envio_devolucion_' + pieza.id);
            const envioDevolucionValue = getValues('precio-envio-devolucion_' + pieza.id);

            if(venta.envios && venta.envios?.length > 0) {
                envioPieza = venta.envios.find(e => e.piezas.find(p => p.id === pieza.id))?.precio;
            } else {
                envioPieza = pieza.precio_transporte;
            }

            let item = { 
                pieza_id: pieza.id, 
                envio: true,
                cancelar_envio: cancelarEnvio,
                importe: pieza.precio_venta, 
                importe_envio: envioPieza,
                unidades: pieza.venta_relationship.unidades,
                casco: pieza.casco > 0 ? true : false,
                importe_casco: pieza.casco,
                envio_devolucion: envioDevolucion,
                importe_envio_devolucion: envioDevolucionValue
            };
            devolucionPiezas.push(item);
        })

        const devolucion = {
            concepto: '',
            total: venta.piezas.reduce((acc, cur) => acc = acc + (parseFloat(cur.precio_venta)), 0),
            total_envio: envio,
            total_casco: venta.piezas.reduce((acc, cur) => acc = acc + (parseFloat(cur.casco)), 0),
            total_envio_devolucion: devolucionPiezas.reduce((acc, cur) => acc = acc + (parseFloat(cur.importe_envio_devolucion)), 0),
            venta_id : ventaId,
            tipo_devolucion_id: refoundType,
            codigo_autorizacion: getValues('codigo_autorizacion'),
            piezas: devolucionPiezas
        }

        await crearDevolucion(devolucion);
    }

    const onSubmitDevolucionParcial = async (data, e) => {
        e.preventDefault();
        const devolucionPiezas = [];

        Object.entries(data).forEach(([key, value]) => {
            if(key.includes('pieza')) {
                let piezaId = parseInt(key.split('_')[1]);
                let isIn = devolucionPiezas.find(f => f.pieza_id === piezaId);
                const envioPieza = data['envio_' + piezaId];
                const cascoPieza = data['casco_' + piezaId];
                const cancelarEnvio = data['cancelar-envio_' + piezaId];
                const envioDevolucion = data['envio_devolucion_' + piezaId];
                const envioDevolucionValue = data['precio-envio-devolucion_' + piezaId];

                if(!isIn && (value || envioPieza || cascoPieza)) {
                    let item = { 
                        pieza_id: piezaId, 
                        envio: envioPieza,
                        cancelar_envio: cancelarEnvio,
                        importe: value ? parseFloat(data['precio_' + piezaId]) : 0, 
                        importe_envio: envioPieza ? parseFloat(data['precio-envio_' + piezaId]) : 0,
                        casco: cascoPieza,
                        importe_casco: cascoPieza ? parseFloat(data['precio-casco_' + piezaId]) : 0,
                        unidades: parseInt(data['unidades_' + piezaId]),
                        envio_devolucion: envioDevolucion,
                        importe_envio_devolucion: envioDevolucion ? parseFloat(envioDevolucionValue) : 0
                    };
                    devolucionPiezas.push(item);
                }
            }
        });

        const devolucion = {
            concepto: '',
            total: devolucionPiezas.reduce((acc, cur) => acc = acc + (parseFloat(cur.importe)), 0),
            total_envio: devolucionPiezas.reduce((acc, cur) => acc = acc + (parseFloat(cur.importe_envio)), 0),
            total_casco: devolucionPiezas.reduce((acc, cur) => acc = acc + (parseFloat(cur.importe_casco)), 0),
            total_envio_devolucion: devolucionPiezas.reduce((acc, cur) => acc = acc + (parseFloat(cur.importe_envio_devolucion)), 0),
            venta_id : ventaId,
            tipo_devolucion_id: refoundType,
            codigo_autorizacion: getValues('codigo_autorizacion'),
            piezas: devolucionPiezas
        }

        await crearDevolucion(devolucion);
     }

     const crearDevolucion = async(devolucion) => {
        dispatch({type: 'loading/set', payload: true});
        const devolucionResult = await newDevolucion(devolucion)
        .catch(function (error) {
            dispatch({type: 'loading/set', payload: false});
            toast.error(setErrorMessage(error))
        });

        if(devolucionResult && devolucionResult.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(devolucionResult.message)
            setRefoundType(null);
            setIsSelectedRefoundType(false);
            navigate('/devoluciones/1', { replace: true });
        }
     }

     return (
         <div className="w-100 d-flex align-items-center">
            { !isSelectedRefoundType &&
                <div className="w-100 d-flex align-items-center justify-content-center">
                    <div className="col-12 col-md-4 d-flex flex-column">
                        <p className="text-center fw-bold text-uppercase">Tipo de devolución</p>

                        <div className="d-flex align-items-center justify-content-between mt-5">
                            <button className="btn btn-danger" onClick={() => handleNewDevolucion(2)}>Total</button>
                            <button className="btn btn-warning" onClick={() => handleNewDevolucion(1)}>Parcial</button>
                        </div>
                    </div>
                </div>
            }

            { (isSelectedRefoundType) && 
                <div className="w-100 mt-4">
                    <h5 className="fw-bold">Devolución {refoundType === 2 ? 'Total' : 'Parcial'}</h5>
                    <p>#Presupuesto: {venta?.presupuesto?.numero}</p>
                    <p>#Venta: {ventaId}</p>
                    

                    <form className="form mb-4" onSubmit={handleSubmit(onSubmitDevolucionParcial)}>
                        <div className="w-100 table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Pieza</th>
                                        <th>importe pieza</th>
                                        <th>importe envío</th>
                                        <th>importe envío devolución</th>
                                        <th>cancelar envío</th>
                                        <th>importe casco</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { venta?.piezas?.map(pieza => {
                                        const envios = venta?.envios;
                                        let precioEnvio, envio;

                                        if(envios && envios.length > 0) {
                                            envio = envios.find(f => f.piezas.find(p => p.id === pieza.id));

                                            if(envio) precioEnvio = envio.precio;
                                        } else { precioEnvio = pieza.precio_transporte; }
                                        
                                        return (
                                            <tr key={'devolucion-pieza-' + pieza.id}>
                                                <td className="align-middle">
                                                    <FontAwesomeIcon icon={faCogs} size="1x" color="#215732"></FontAwesomeIcon>
                                                    <span className="ms-3">{pieza.nombre}</span>
                                                </td>
                                                <td className="align-middle">
                                                    <div className="d-flex align-items-center">
                                                        <div className="d-flex align-items-center justify-content-between me-3">
                                                            <input 
                                                                type="checkbox"
                                                                {...register("pieza_" + pieza.id)} 
                                                                disabled={isDisabled}
                                                                defaultChecked={refoundType === 2 ? true : false} />
                                                        </div>

                                                        <div className="d-flex flex-column me-3" style={((!watch("pieza_" + pieza.id) && refoundType !== 2) ? { opacity: 0 } : { opacity: 100})}>
                                                            <input 
                                                                className="form-control"
                                                                type="number"
                                                                step="0.01"
                                                                {...register("precio_" + pieza.id)} 
                                                                disabled={isDisabled}
                                                                defaultValue={pieza.precio_venta} />
                                                        </div>

                                                        <div className="d-flex flex-column" style={((!watch("pieza_" + pieza.id) && refoundType !== 2) ? { opacity: 0 } : { opacity: 100})}>
                                                            <input 
                                                                className="form-control"
                                                                type="number"
                                                                step="0.01"
                                                                disabled={isDisabled}
                                                                {...register("unidades_" + pieza.id)} 
                                                                placeholder="Unidades"
                                                                defaultValue={pieza.venta_relationship.unidades} />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <div className="d-flex align-items-center">
                                                        <div className="d-flex align-items-center justify-content-between me-3">
                                                            <input 
                                                                type="checkbox"
                                                                {...register("envio_" + pieza.id)} 
                                                                disabled={isDisabled}
                                                                defaultChecked={refoundType === 2 ? true : false} />
                                                        </div>

                                                        <div className="d-flex flex-column" style={((!watch("envio_" + pieza.id) && refoundType !== 2) ? { opacity: 0 } : { opacity: 100})}>
                                                            <input 
                                                                className="form-control"
                                                                type="number"
                                                                step="0.01"
                                                                disabled={isDisabled}
                                                                {...register("precio-envio_" + pieza.id)} 
                                                                defaultValue={precioEnvio} />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <div className="d-flex align-items-center">
                                                        <div className="d-flex align-items-center justify-content-between me-3">
                                                            <input 
                                                                type="checkbox"
                                                                {...register("envio_devolucion_" + pieza.id)} 
                                                                defaultChecked={false} />
                                                        </div>

                                                        <div className="d-flex flex-column" style={((!watch("envio_devolucion_" + pieza.id)) ? { opacity: 0 } : { opacity: 100})}>
                                                            <input 
                                                                className="form-control"
                                                                type="number"
                                                                step="0.01"
                                                                {...register("precio-envio-devolucion_" + pieza.id)} 
                                                                defaultValue={0} />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <div className="d-flex align-items-center">
                                                        <input 
                                                            type="checkbox"
                                                            {...register("cancelar-envio_" + pieza.id)} 
                                                            defaultChecked={refoundType === 2 ? true : false} />

                                                        { (envio) &&
                                                            <div className="ms-3">
                                                                <p>{envio?.codigo_transporte} | {envio?.codigo_envio}</p>
                                                                <p className={"fw-bold " + getEstado(envio?.transportista_id, envio?.estado?.codigo)}>
                                                                    {envio?.estado?.nombre}
                                                                </p>
                                                            </div>
                                                        }
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <div className="w-100 d-flex align-items-center">
                                                        <div className="d-flex align-items-center justify-content-between me-3">
                                                            <input 
                                                                type="checkbox"
                                                                {...register("casco_" + pieza.id)} 
                                                                disabled={isDisabled}
                                                                defaultChecked={refoundType === 2 ? true : false} />
                                                        </div>

                                                        <div className="d-flex flex-column" style={((!watch("casco_" + pieza.id) && refoundType !== 2) ? { opacity: 0 } : { opacity: 100})}>
                                                            <input 
                                                                className="form-control"
                                                                type="number"
                                                                step="0.01"
                                                                disabled={isDisabled}
                                                                {...register("precio-casco_" + pieza.id)} 
                                                                defaultValue={pieza.casco} />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }) }
                                </tbody>
                            </table>
                        </div>

                        <div className="d-flex w-100 justify-content-center mt-5">
                            <button type="button" className="btn btn-primary" onClick={() => handleOpenModal()}>Crear</button>
                            <button type="submit" ref={submitBtn} className="btn btn-primary d-none"></button>
                        </div>
                    </form>
                </div>
            }

            { showConfirmModal && 
                <ConfirmModal 
                    onConfirmAction={handleConfirmDevolucion} 
                    title={'Generar devolucion parcial'} 
                    description={'Estás seguro de generar la Devolucion ' + (refoundType === 2 ? 'Total' : 'Parcial')}
                    state={showConfirmModal}>
                </ConfirmModal>
            }
         </div>
     )
}