import { useState, useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

export default function IncidenciaForm({
    incidencia,
    onSubmitIncidencia
}) {
    const { register, handleSubmit, formState: { errors }, watch, reset, setValue } = useForm({
        defaultValues: {
            culpa: incidencia.culpa,
            subtipo_incidencia_id: incidencia.subtipo_incidencia_id || '',
            tipo_incidencia_id: incidencia.tipo_incidencia_id,
        }
    });
    const tiposIncidencia = useSelector(state => {
        const { tipo_incidencias } = state;
        return tipo_incidencias;
    });
    const subtiposIncidencia = useSelector((state) => {
        return state.subtipos_incidencia?.filter((subtipo) => subtipo.active);
    });
    const posiblesCulpables = ['Proveedor', 'Cliente', 'Recomotor', 'Transporte proveedor', 'Transporte propio', 'Atencion comercial'];

    useEffect(() => {
        if(incidencia) {
            reset({
                'culpa': incidencia.culpa,
                'subtipo_incidencia_id': incidencia.subtipo_incidencia_id,
                'tipo_incidencia_id': incidencia.tipo_incidencia_id
            })
        }
    }, [incidencia]);

    const handleSubmitIncidencia = async (data, e) => {
        e.preventDefault();
        onSubmitIncidencia(data);
    };

    const handleChangeTipoIncidencia = (e) => { 
        setValue('tipo_incidencia_id', e.target.value); 
        setValue('subtipo_incidencia_id', '');
    }

    const handleChangeSubtipoIncidencia = (e) => { setValue('subtipo_incidencia_id', e.target.value); }
 
    return (
        <div className="w-100 d-flex flex-column justify-content-center">
            <form className="form mb-4" onSubmit={handleSubmit(handleSubmitIncidencia)}>
                <div className="w-100 d-flex flex-column">
                    <div className="w-100">
                        <div className="w-100">
                            <label>Tipo de incidencia</label>

                            <div className="w-100 mt-2">
                                { tiposIncidencia.map(tipo => {
                                    return (
                                        <div className="form-group radiobutton-group" key={'tipo_incidencia' + tipo.id}>
                                            <label htmlFor="tipo_incidencia" style={{fontWeight: 'normal'}} 
                                                className="radiobutton-group-text d-flex flex-wrap align-items-center">
                                                <input
                                                    {...register("tipo_incidencia_id", { required: true })}
                                                    type="radio"
                                                    name="tipo_incidencia_id"
                                                    value={tipo.id}
                                                    id="tipo_incidencia_id"
                                                    defaultChecked={tipo.id === incidencia.tipo_incidencia_id}
                                                    onChange={handleChangeTipoIncidencia}
                                                /> &nbsp;
                                                <b className="mx-1">{tipo.nombre}:</b>
                                            </label>

                                            <small>{tipo.descripcion}</small>

                                            { (watch('tipo_incidencia_id') == tipo.id) && 
                                                subtiposIncidencia && 
                                                (subtiposIncidencia.length > 0) && 
                                                (subtiposIncidencia.some(subtipo => subtipo.tipo_incidencia_id === tipo.id)) && (
                                                    <div style={{marginLeft: "1rem"}}>
                                                        <select
                                                            {...register("subtipo_incidencia_id")}
                                                            className="w-100"
                                                            defaultValue={''}
                                                            onChange={handleChangeSubtipoIncidencia}
                                                            >
                                                            <option value={''}>
                                                                No especificar
                                                            </option>
                                                            {subtiposIncidencia
                                                                .filter((subtipo) => subtipo.tipo_incidencia_id === tipo.id)
                                                                .map((subtipoIncidencia) => (
                                                                    <option
                                                                    key={"tipo-incidencias-" + subtipoIncidencia.id}
                                                                    value={subtipoIncidencia.id}
                                                                    >
                                                                    {subtipoIncidencia.nombre}
                                                                    </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                            )}
                                        </div> 
                                    )})
                                }

                                <div className="form-invalid mb-3">
                                    {errors.tipo_incidencia_id?.type === 'required' && "El tipo de incidencia es obligatorio"}
                                </div>
                            </div>
                        </div>

                        <hr /> 

                        <div className="w-100">
                            <label>¿Quién se responsabiliza?</label>

                            <div className="w-100 mt-2">
                                { posiblesCulpables.map((culpable, i) => {
                                    return (
                                        <div className="form-group radiobutton-group" key={'culpable-' + i}>
                                            <label htmlFor="culpa" style={{fontWeight: 'normal'}} className="radiobutton-group-text d-flex align-items-center">
                                                <input
                                                    {...register("culpa", { required: true })}
                                                    type="radio"
                                                    name="culpa"
                                                    value={culpable.replace(' ', '-').toLocaleLowerCase()}
                                                    id="culpa"
                                                /> &nbsp;
                                                {culpable}
                                            </label>
                                        </div> 
                                    ) })
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex align-items-center align-self-end">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    );
}