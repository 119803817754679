/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from '../shared/components/Header'
import { useDispatch, useSelector } from "react-redux";
import { fetchDevolucionesMercancias } from "../../../../redux/devoluciones_mercancias";
import Loading from "../shared/components/Loading";
import InfoModal from "../shared/components/InfoModal";
import DevolucionMercanciaForm from "./components/DevolucionMercanciaForm";
import { toast } from "react-toastify";
import { deleteDevolucion, deleteImage, newDevolucion, updateDevolucion, updateDevolucionWithEtiqueta, uploadImages } from "../shared/services/devoluciones_mercancias";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import ItemsTable from "./components/ItemsTable";
import Detail from "./components/Detail";
import Images from "./components/Images";
import AssignVentaForm from "./components/AssignVentaForm";
import { setErrorMessage } from "../shared/helpers/functionalities";

export default function DevolucionMercancias() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { page } = useParams();
  const currPage = parseInt(page); 
  const [currentPage, setPage] = useState(currPage);
  const [tab, setTab] = useState('encurso');
  const [currentDevolucion, setCurrentDevolucion] = useState(null);
  const [openNew, setOpenNew] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [openImages, setOpenImages] = useState(false);
  const [openAssignVenta, setOpenAssignVenta] = useState(false);
  const devoluciones = useSelector(state => {
    return state.devolucion_mercancias.entities
  });
  const devolucionesStatus = useSelector(state => {
    return state.devolucion_mercancias.status
  });
  const searcher = useSelector(state => {
    return state.search
  })
  const [submitDevilucionLoading, setSubmitDevilucionLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchDevolucionesMercancias(currPage, getQuery(1)));
  }, [searcher]);

  useEffect(() => {
    if(currentDevolucion) {
      const updatedCurrentDev = devoluciones.devoluciones.find(dev => dev.id === currentDevolucion.id)
      setCurrentDevolucion(updatedCurrentDev)
    }
  }, [devoluciones])

  const getQuery = (section) => {
    const isEmpty = Object.values(searcher).every(x => x === null || x === '');
    let query = !isEmpty ? 'search=' + (searcher?.search ? searcher?.search : '') 
    : null;

    if(!isEmpty) {
        query += '&seccion=' + section;
      } else {
        query = 'seccion=' + section;
      }

    return query;
  }
  
  const handleSearch = (search, searchData) => {
    setPage(1);
    navigate(`/devoluciones-mercancias/${1}`);
    dispatch({type: "search/set", payload: searchData});     
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
    navigate(`/devoluciones-mercancias/${newPage}`);
    dispatch(fetchDevolucionesMercancias(newPage, getQuery(tab === 'encurso' ? 1 : 0)));
  }

  const handleChangeTab = (e, newTab) => { 
    setTab(newTab);
    setPage(1);
    navigate(`/devoluciones-mercancias/${1}`);
    dispatch(fetchDevolucionesMercancias(1, getQuery(newTab === 'encurso' ? 1 : 0)));
  }

  const handleNewDevolucionMercancia = () => { setOpenNew(true); }
  const handleCloseNewDevolucion = () => { setOpenNew(false); }
  const handleSubmitDevolucion = async(data) => {
    if(submitDevilucionLoading) return;
    dispatch({type: 'loading/set', payload: true});
    setSubmitDevilucionLoading(true);
    const response = await newDevolucion(data)
    .catch(function (error) {
      toast.error(setErrorMessage(error))
      dispatch({type: 'loading/set', payload: false});
      setSubmitDevilucionLoading(false);
    });

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
      handleCloseNewDevolucion();
      dispatch(fetchDevolucionesMercancias(currPage, getQuery(tab === 'encurso' ? 1 : 0)));
      setSubmitDevilucionLoading(false);
    }
  }

  const handleOpenViewDetail = (devolucion) => {
    setCurrentDevolucion(devolucion);
    setOpenDetail(true);
  }
  const handleCloseViewDetail = () => {
    setCurrentDevolucion(null);
    setOpenDetail(false);
  }


  const handleOpenImages = (devolucion) => {
    setCurrentDevolucion(devolucion);
    setOpenImages(true);
  }
  const handleCloseImages = () => {
    setCurrentDevolucion(null);
    setOpenImages(false);
  }
  const handleUploadImages = async(data)  => {
    dispatch({type: 'loading/set', payload: true});
    const files = new FormData();
    files.append('id', currentDevolucion.id);

    for (let i = 0; i < data.files?.length; i++) {
        files.append(`files[${i}]`, data.files[i])
    }

    const response = await uploadImages(files)
    .catch(function (error) {
      toast.error(setErrorMessage(error))
      dispatch({type: 'loading/set', payload: false});
    });

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
      dispatch(fetchDevolucionesMercancias(currPage, getQuery(tab === 'encurso' ? 1 : 0)));
    }
  }
  const handleDeleteImage = async(img) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await deleteImage({
      id: currentDevolucion.id,
      file: img
    }).catch(function (error) {
      toast.error(setErrorMessage(error))
      dispatch({type: 'loading/set', payload: false});
    });

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
      dispatch(fetchDevolucionesMercancias(currPage, getQuery(tab === 'encurso' ? 1 : 0)));
    }
  }

  const handleUpdate = async(data) => {
    try {
      setOpenAssignVenta(false);
      dispatch({type: 'loading/set', payload: true});
      
      const response = await updateDevolucion(data);

      if(response && response.success) {
        toast.success(response.message);
        dispatch(fetchDevolucionesMercancias(currPage, getQuery(tab === 'encurso' ? 1 : 0)));
      }
    } catch (error) {
      toast.error(setErrorMessage(error))
    } finally {
      dispatch({type: 'loading/set', payload: false});
    }
  }
  
  const handleUpdateWithEtiqueta = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await updateDevolucionWithEtiqueta(data)
    .catch(function (error) {
      toast.error(setErrorMessage(error))
      dispatch({type: 'loading/set', payload: false});
    });
  
    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
      dispatch(fetchDevolucionesMercancias(currPage, getQuery(tab === 'encurso' ? 1 : 0)));
    }
  }

  const handleOpenAssignVenta = async(devolucion) => {
    setCurrentDevolucion(devolucion);
    setOpenAssignVenta(true);
  }
  const handleCloseAssignVenta = async() => {
    setCurrentDevolucion(null);
    setOpenAssignVenta(false);
  }

  const handleDeleteDevolucion = async(devolucionId) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await deleteDevolucion(devolucionId)
    .catch(function (error) {
      toast.error(setErrorMessage(error))
      dispatch({type: 'loading/set', payload: false});
    });
  
    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
      dispatch(fetchDevolucionesMercancias(currPage, getQuery(tab === 'encurso' ? 1 : 0)));
    }
  }

  if(devolucionesStatus.loading === 'pending' || devolucionesStatus.loading === 'idle') {
    return ( <Loading /> );
  }

  if(devolucionesStatus.loading === 'succeed' || devolucionesStatus.loading === 'rejected') {
    return (
      <div className="content pt-3">
        <div className="w-100 d-flex flex-wrap align-items-end justify-content-between">
          <Header 
            viewSearch={true} 
            onSearch={handleSearch}
            onNew={handleNewDevolucionMercancia}
            ></Header>
        </div>

        <div className="w-100 d-flex flex-column align-items-end content-table">
            <div className="w-100">
                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChangeTab} aria-label="Estados de Devolución de mercancías">
                        <Tab label="En curso" value="encurso" />
                        <Tab label="Finalizadas" value="finalizadas" />
                    </TabList>
                    </Box>
                    <TabPanel value="encurso">
                        <ItemsTable 
                            id={'encurso'}
                            currPage={currentPage}
                            onPageChange={handleChangePage}
                            devoluciones={devoluciones} 
                            onViewDetail={handleOpenViewDetail}
                            onAdminImages={handleOpenImages}
                            onUpdate={handleUpdate}
                            onUpdateWithEtiqueta={handleUpdateWithEtiqueta}
                            onAssignVenta={handleOpenAssignVenta}
                            onDeleteDevolucion={handleDeleteDevolucion}
                        />
                    </TabPanel>
                    <TabPanel value="finalizadas">
                        <ItemsTable 
                            id={'finalizadas'}
                            currPage={currentPage}
                            onPageChange={handleChangePage}
                            devoluciones={devoluciones} 
                            onViewDetail={handleOpenViewDetail}
                            onAdminImages={handleOpenImages}
                            onUpdate={handleUpdate}
                            onUpdateWithEtiqueta={handleUpdateWithEtiqueta}
                            onAssignVenta={handleOpenAssignVenta}
                            onDeleteDevolucion={handleDeleteDevolucion}
                        />
                    </TabPanel>
                </TabContext>
            </div>
        </div>

        { (openNew) && 
            <InfoModal
                state={openNew}
                title={"Nueva devolución de mercancía"}
                content={
                    <DevolucionMercanciaForm 
                        onSubmitDevolucion={handleSubmitDevolucion}
                    />
                }
                width={'sm'}
                onClose={handleCloseNewDevolucion}
            ></InfoModal>
        }

        { (openDetail) && 
            <InfoModal
                state={openDetail}
                title={"Detalle de devolución de mercancía " + currentDevolucion.numero}
                content={
                    <Detail 
                        devolucion={currentDevolucion}
                    />
                }
                width={'lg'}
                onClose={handleCloseViewDetail}
            ></InfoModal>
        }

        { (openImages) && 
            <InfoModal
                state={openImages}
                title={"Administrar imágenes de devolución de mercancía " + currentDevolucion.numero}
                content={
                    <Images 
                        devolucion={currentDevolucion}
                        onDelete={handleDeleteImage}
                        onUpload={handleUploadImages}
                    />
                }
                width={'lg'}
                onClose={handleCloseImages}
            ></InfoModal>
        }

        { (openAssignVenta) && 
            <InfoModal
                state={openAssignVenta}
                title={"Asignar venta a devolución de mercancía " + currentDevolucion.numero}
                content={
                    <AssignVentaForm 
                        devolucion={currentDevolucion}
                        onSubmitVenta={handleUpdate}
                    />
                }
                width={'md'}
                onClose={handleCloseAssignVenta}
            ></InfoModal>
        }
      </div>
    )
  }
}