// LOAD ALL THE READ ONLY DATA
import { getIncidenciaSteps } from "../views/Dashboard/components/shared/services/incidenciasSteps";
import { makeFetchingReducer, makeAsyncTypes, asyncMac, makeSetReducer } from "./utils";

const asyncSteps = makeAsyncTypes('steps');

const [setPending, setFullFilled, setError ] = asyncMac(asyncSteps);

export const stepsFullfilledReducer = makeSetReducer(['steps/fullfilled']);

export const fetchingReducer = makeFetchingReducer([
    'steps/pending', 
    'steps/fullfilled', 
    'steps/rejected'
]);

export const fetchSteps = () => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getIncidenciaSteps()
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}