import React, { memo, useEffect } from "react";
import { useState } from "react";
import { isEqual } from 'lodash';
import { SidebarData } from "./SidebarData";
import './Navbar.scss';
import logo from '../../../../images/logo_white.png';
import UserAvatar from "../shared/components/Header/components/UserAvatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBell, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Notificaciones from "../Notificaciones";
import useSound from "use-sound";
import alertSfx from './sounds/alert.mp3';

const Navbar = ({
    onLogout,
    onOpenReportProblem
}) => {
    const dispatch = useDispatch();
    let pathname = window.location.pathname === '/' ? 'home' : window.location.pathname.split('/')[1];
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
    const [sideBar, setSidebar] = useState(false);
    const [notifBar, setNotifBar] = useState(false);
    const [isArchived, setIsArchived] = useState(false);
    const avisos = useSelector(state => state.avisos.entities );
    const count = parseInt(localStorage.getItem('notificaciones_count')) || 0;
    const [play] = useSound(alertSfx);
    const notificaciones = useSelector(state => {
        return state.notificaciones.entities
    });

    useEffect(() => {
        if(notificaciones.notificaciones.length > 0) {
            if(!count) {
                localStorage.setItem('notificaciones_count', notificaciones.notificaciones.length);
            } else {
                if(count !== notificaciones.notificaciones.length) {
                    localStorage.setItem('notificaciones_count', notificaciones.notificaciones.length);
                    if(!isArchived) play();
                    else setIsArchived(false);
                }
            }
        }
    }, [notificaciones]);
    
    const handleClickMenuItem = (path) => {
        dispatch({type: "search/set", payload: ""})
        localStorage.removeItem('comerciales-tab');
        localStorage.removeItem('incidencias-tab');
        localStorage.removeItem('incidencias-page');
        if(path === '/' || path === '/finanzas' || path === '/recordatorios') navigate(path);
        else navigate(path + '/1');
    }

    const showSidebar = (show) => { setSidebar(show); }
    const showNotifications = (show) => { setNotifBar(show); }

    const handleArchiveNotificaciones = () => { setIsArchived(true); }

    const NavItem = memo(({children}) => {
        return (
            <span>
                {children}
            </span>
        )
    }, isEqual)

    return (
        <>
            <div className="navbar-sidebar">
                <Link to="#" className="menu-bars" onMouseEnter={() => showSidebar(true)}>
                    <FontAwesomeIcon icon={faBars} size="2x" color="#215732" />
                </Link>

                <span className="notification-icon" title="Notificaciones" style={{cursor: 'pointer'}} onMouseEnter={() => showNotifications(true)}>
                    <FontAwesomeIcon className="notification-menu" icon={faBell} size="2x" color="#215732" />
                    <span className="notification-number">{notificaciones?.notificaciones?.length}</span>
                </span>
            </div>
            <nav className={sideBar ? "nav-menu active" : "nav-menu"} onMouseLeave={() => showSidebar(false)}>
                <ul className="nav-menu-items">
                    <li>
                        <div className="w-100 d-flex align-items-center justify-content-between">
                            <div className="navbar-brand">
                                <img src={logo} className="brand" style={{height: '50px'}} alt="Recomotor" />
                            </div>
                            <UserAvatar 
                                style={{ cursor: 'pointer' }} 
                                onOpenReportProblem={onOpenReportProblem}
                                onLogout={onLogout} />
                        </div>
                    </li>
                    { SidebarData.map((item, index) => {
                        if(
                            item.permission === currentUser?.rol_id || 
                            (item.permission === 0) || 
                            (currentUser?.rol_id === 1)  || 
                            (item.title === 'Users' && currentUser.responsable) ||
                            (item.title === 'Comerciales' && currentUser.responsable) || 
                            (item.title === 'Finanzas' && currentUser.responsable) ||
                            (item.title === 'Clientes' && ((currentUser?.rol_id === 4 || currentUser?.rol_id === 5) || currentUser.responsable))
                        ) {
                            return (
                                <NavItem key={index}>
                                    <span
                                        onClick={() => handleClickMenuItem(item.path)}
                                        className={`${item.cName} ${item.title.toLowerCase() === pathname ? "active" : ""}`}>
                                            <li style={{ cursor: 'pointer' }}>
                                                <span>{item.title}</span>
                                                { item.title === 'Incidencias' && avisos && avisos.length > 0 &&
                                                    <span title="Tienes avisos sin leer">
                                                        <FontAwesomeIcon icon={faExclamationCircle} size="1x" className="aviso" color="#FFFFFFF" />
                                                    </span>
                                                }
                                            </li>
                                    </span>
                                </NavItem>
                            )
                        } else {
                            return '';
                        }
                    })}
                    
                </ul>
            </nav>

            <nav className={notifBar ? "nav-menu-notifications active" : "nav-menu-notifications"} onMouseLeave={() => showNotifications(false)}>
                <Notificaciones 
                    notificaciones={notificaciones} 
                    onArchived={handleArchiveNotificaciones}
                />
            </nav>
        </>
    );
}

export default memo(Navbar)