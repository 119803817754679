import API from "../../../../../services/api";

export const getSubtiposIncidencia = async () => {
  return await API.get("/subtipos-incidencia").then((res) => res.data);
};

export const addSubtipoIncidencia = async (data) => {
  return await API.post("/subtipos-incidencia", data).then((res) => res.data);
};

export const editSubtipoIncidencia = async (id, data) => {
  return await API.put(`/subtipos-incidencia/${id}`, data).then(
    (res) => res.data
  );
};
