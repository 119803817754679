import API from '../../../../../services/api';
import { headers } from '../helpers/functionalities';

export const getDevolucionesMercancias = async (page = null, search = null) => {
    const pagination = page !== null ? '?page=' + page : '';
    const searcher = search !== null && page !== null ? '&' + search : search !== null ? '?' + search : '';
    return await API.get('/devolucion-mercancias' + pagination + searcher).then(res => res.data);
}

export const searchVenta = async(search) => {
    return await API.get('/devolucion-mercancias/search/venta?search=' + search).then(res => res.data);
}

export const newDevolucion = async (devolucion) => {
    return API.post('/devolucion-mercancias', devolucion, (devolucion instanceof FormData ? headers : null)).then(res => res.data);
}

export const updateDevolucion = async (devolucion) => {
    return API.put('/devolucion-mercancias/' + devolucion.id, devolucion).then(res => res.data);
}

export const updateDevolucionWithEtiqueta = async (formData) => {
    return API.post('/devolucion-mercancias/with-etiqueta', formData, headers).then(res => res.data);
}

export const uploadImages = async (devolucion) => {
    return API.post('/devolucion-mercancias/' + devolucion.get("id") + '/upload-img', devolucion, headers).then(res => res.data);
}

export const deleteImage = async (devolucion) => {
    return API.post('/devolucion-mercancias/' + devolucion.id + '/delete-img', devolucion).then(res => res.data);
}

export const deleteDevolucion = async (devolucionId) => {
    return API.delete('/devolucion-mercancias/' + devolucionId).then(res => res.data);
}