import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRecycle } from "@fortawesome/free-solid-svg-icons";

export default function StepForm({
  subtiposIncidenciaInactivos,
  getTipoIncidenciaName,
  onRecicle,
}) {
  /** Methods **/
  const handleOnRecicle = (subtipo) => {
    onRecicle(subtipo);
  };

  /** Render **/
  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th className="text-center" style={{ width: "80px" }}>
              ID
            </th>
            <th>Nombre</th>
            <th className="text-center" style={{ width: "200px" }}>
              Tipo de incidéncia
            </th>
            <th className="text-center" style={{ width: "80px" }}>
              Acciones
            </th>
          </tr>
        </thead>
        <tbody>
          {subtiposIncidenciaInactivos?.map((subtipo) => {
            return (
              <tr
                key={"valor-" + subtipo.id}
                style={{ borderBottom: "12px solid white" }}
              >
                <td className="text-center">{subtipo.id}</td>
                <td>{subtipo.nombre}</td>
                <td className="text-center text-uppercase">
                  {getTipoIncidenciaName(subtipo.tipo_incidencia_id) || "-"}
                </td>
                <td className="text-center" width="80px">
                  <div className="d-flex justify-content-center">
                    <span title="Editar" style={{ cursor: "pointer" }}>
                      <FontAwesomeIcon
                        icon={faRecycle}
                        className="action fs-5 pt-1"
                        color="#215732"
                        onClick={() => handleOnRecicle(subtipo)}
                      />
                    </span>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {subtiposIncidenciaInactivos.length === 0 && (
        <p className="w-100 text-center border-top pt-3">
          No se encontraron resultados
        </p>
      )}
    </div>
  );
}
