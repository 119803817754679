export const getActions = (item) => {
    return {
        actions: [
            {
                label: 'Ver detalles',
                icon: 'details',
                action: 'details'
            },
            {
                label: 'Asignar venta',
                icon: 'pay',
                action: 'assign'
            },
            {
                label: 'Administrar imágenes',
                icon: 'image',
                action: 'images'
            },
            (item?.active) ?
            {
                label: 'Finalizar',
                icon: 'disable',
                action: 'disable'
            } 
            : 
            {
                label: 'Reactivar',
                icon: 'enable',
                action: 'enable'
            },
            {
                label: 'Eliminar',
                icon: 'delete',
                action: 'delete'
            },
        ],
        extra: []
    }
}