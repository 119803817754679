import { useState, useEffect } from "react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { faExclamationCircle, faCheck, faTimes, faTruck } from '@fortawesome/free-solid-svg-icons';
import Pagination from '@mui/material/Pagination';
import { useDispatch } from "react-redux";
import { CustomTooltip, getEstado, setErrorMessage } from "../../../shared/helpers/functionalities";
import { toast } from "react-toastify";
import moment from "moment";
import { obtenerEstado } from "../../../../../../redux/incidencias";
import ConfirmModal from "../../../shared/components/ConfirmModal";
import { regenerarEnvioPendiente } from "../../../shared/services/envios";
import ActionsMenu from "../../../shared/components/ActionsMenu";
import { getActions } from "./actions";
import config from '../../../../../../config';

export default function IncidenciaTable({
    id,
    currPage,
    incidencias,
    onPageChange,
    onGeneratePDF,
    onUpload,
    onAviso,
    onOpenSubEstados,
    onFinalizar,
    onReactivar,
    onFinanzas,
    onNewEnvio,
    onDelete,
    onChangeResponsable,
    onEdit
}) {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [incidenciasList, setIncidenciasList] = useState(incidencias.incidencias);
    const [currentEnvio, setCurrentEnvio] = useState(null);
    const [openConfirmRegenerarEnvio, setOpenConfirmRegenerarEnvio] = useState(false);
 
    useEffect(() => {
        const list = incidencias.incidencias;
        setIncidenciasList(list);
    }, [id, currPage, incidencias]);

    const handleChangePage = (event, newPage) => { onPageChange(newPage, id); }
  
    const getPaginationCount = () => {
        var totalPages = incidenciasList.total_items < 15 ? 1 : Math.ceil(incidenciasList.total_items / 15);
        return parseInt(totalPages);
    }

    const handleAction = (action, incidencia) => {
        if(action === 'details') {
            window.open(`/incidencia/${incidencia.id}`, "_blank", "noreferrer");
        } else if(action === 'shipment') {
            onNewEnvio(incidencia)
        } else  if(action === 'edit') {
            onEdit(incidencia)
        } else if(action === 'change-responsable') {
            onChangeResponsable(incidencia)
        } else if(action === 'upload') {
            onUpload(incidencia.id)
        } else if(action === 'avisos') {
            onAviso(incidencia)
        } else if(action === 'delete') {
            onDelete(incidencia.id)
        } else if(action === 'status') {
            onOpenSubEstados(incidencia)
        } else if(action === 'reactivate') {
            onReactivar(incidencia.id)
        } else if(action === 'finance') {
            onFinanzas(incidencia.id)
        } else if(action === 'finish') {
            onFinalizar(incidencia.id)
        } else if(action === 'survey-link') {
            handleCopySurveyLink(incidencia.id);
        }
    }

    const handleGoToEnvio = (envio) => {
        dispatch({type: "search/set", payload: {search: envio.codigo_transporte }});
        navigate('/envios/1', { replace : true});
    }
    
    const handleCopyText = (incidencia) => {
        let piezas = "", descripcion = "";
    
        incidencia.piezas.map(pieza => piezas += getPiezaInfo(pieza));
        incidencia.steps.map(step => step.incidencia_step_id === 1 || step.incidencia_step_id === 13 || step.incidencia_step_id === 23 || step.incidencia_step_id === 32 || step.incidencia_step_id === 41 || step.incidencia_step_id === 69 ? descripcion = step.comentario : '')
    
        const text = 'Hola ' + incidencia.proveedor.nombre.toUpperCase() + ',\n'
          + 'Tenemos una incidencia con esta pieza que compramos el día ' + moment(incidencia.venta.fecha).format('DD-MM-YYYY') + ':\n\n'
          + piezas + '\n\nEl problema que tenemos es que: \n' + descripcion + '\n\nAdjunto foto, vídeo, o diagnosis como prueba. \n\n'
          + 'Necesitaría saber que solución nos pueden dar al respecto, si disponen de otra pieza necesitaría que salga hoy mismo para que el cliente no se quede si poder entregar el vehículo.';
    
        window.navigator.clipboard
            .writeText(text)
            .then(() => toast.success('Texto copiado!'));
        window.open(`/incidencia/${incidencia.id}`, "_blank", "noreferrer");
    }

    const handleCopySurveyLink = async(incidenciaId) => {
        window.navigator.clipboard
                .writeText(config.recomotor.landingUrl + '/incidencias-vo/' + incidenciaId + '/encuesta')
                .then(() => toast.success('Texto copiado!'));
    }
    
    const getPiezaInfo = (pieza) => {
        return pieza.nombre + '\nReferencia: ' + pieza.referencia_pieza + '\nID: ' + pieza.id_referencia_plataforma;
    }

    const handleCheckStatus = (numSeguimiento, transportistaId) => { 
        dispatch({type: 'loading/set', payload: true});
        dispatch(obtenerEstado(numSeguimiento, transportistaId)); 
    }

    const handleOpenConfirmEnvioPendiente = (envio) => {
        setCurrentEnvio(envio);
        setOpenConfirmRegenerarEnvio(true);
    }

    const handleCreateEnvioPendiente = async(confirm) => {
        if(confirm) {
            dispatch({type: 'loading/set', payload: true});
            const response = await regenerarEnvioPendiente({
              envio_id: currentEnvio.id
            }).catch(function (error) {
              toast.error(setErrorMessage(error))
              dispatch({type: 'loading/set', payload: false});
            });
        
            if(response && response.success) {
              dispatch({type: 'loading/set', payload: false});
              toast.success(response.message)
            }
        }

        setOpenConfirmRegenerarEnvio(false);
        setCurrentEnvio(null);
    }

    return (
        <div className="w-100 d-flex flex-column align-items-end">
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Nº</th>
                            <th className="text-center">fecha</th>
                            <th className="text-center">tipo</th>
                            <th className="text-center">factura</th>
                            <th>cliente</th>
                            <th>proveedor</th>
                            <th>Pieza</th>
                            <th className="text-center">€ venta</th>
                            <th className="text-center">envio</th>
                            <th className="text-center">resp</th>
                            <th className="text-center">creada por</th>
                            <th className="text-center">estado</th>
                            <th className="text-center">
                                <span title="Solución Proveedor">SP</span>
                            </th>
                            <th className="text-center">
                                <span title="Solución Cliente">SC</span>
                            </th>
                            <th className="text-center"></th>
                        </tr>
                    </thead>

                    { incidenciasList?.items?.map((incidencia, index) => {
                        const avisosValues = Object.values(incidencia.avisos);
                        const comentario = incidencia.steps[0]?.comentario;

                        const hasAvisos = avisosValues.filter(a => !a.leido);
                        const piezasValues = Object.values(incidencia.piezas);
                        const piezasRows = (piezasValues && piezasValues.length > 0) && piezasValues.map((pieza, i) => {
                            const avisos = i === 0 ? <td rowSpan={piezasValues.length + 1} width="2%" className="text-center">
                                { (hasAvisos.length || (!hasAvisos.length && comentario)) && 
                                    <CustomTooltip
                                        title={
                                        <React.Fragment>
                                            { hasAvisos.length ? <p className="m-0">Asunto: <b>{hasAvisos[0]?.asunto}</b></p> : null }
                                            { comentario && <p className="m-0">Comentario: {comentario}</p> }
                                        </React.Fragment>
                                        }
                                    >
                                        <span style={{cursor: 'pointer'}}>
                                        <FontAwesomeIcon icon={faExclamationCircle} size="1x" className="action" color="#ffc107" /> 
                                        </span>
                                    </CustomTooltip>
                                }
                            </td> : null
                            const numero = i === 0 ? <td rowSpan={piezasValues.length + 1} width="6%">
                                <span style={{cursor: 'pointer'}} onClick={() => handleCopyText(incidencia)}>{incidencia.numero}</span>
                            </td> : null
                            const tipo = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="8%" className="text-center  text-uppercase">{incidencia.tipo_incidencia?.nombre}</td> : null
                            const factura = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="8%" className="text-center">
                                {incidencia?.venta?.factura_id ? 
                                    <span className="text-success" style={{cursor: 'pointer'}} onClick={() => onGeneratePDF(incidencia?.venta, 'factura')}>
                                    {incidencia?.venta?.numero}
                                    </span>
                                    :
                                    <span className="text-success" style={{cursor: 'pointer'}} onClick={() => onGeneratePDF(incidencia?.venta, 'albaran')}>
                                    {incidencia?.venta?.numero}
                                    </span>
                                }
                            </td> : null
                            const fecha = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="10%" className="text-center">
                                <div className="w-100 d-flex flex-column">
                                    <p>{moment(incidencia.fecha).format("DD-MM-YYYY")}</p>
                                </div>
                            </td> : null
                            const envio = i === 0 ?  <td rowSpan={piezasValues.length + 1} className="text-center">
                                { incidencia.envios?.length > 0 ?
                                    incidencia?.envios?.map(envio => {
                                        return (
                                            <div className="d-flex flex-column mb-2" key={'envio-' + envio.id + '-incidencia-' + incidencia.id}>
                                                <span onClick={() => handleGoToEnvio(envio)} className="link">{envio.codigo_transporte}</span>
                                                <small>{envio.codigo_envio}</small>

                                                { (envio?.transportista_id > 1) ? 
                                                    <div>
                                                        { (envio.numero_seguimiento !== "") ?
                                                            <span>
                                                                { (!envio?.estado) && 
                                                                    <span className="link text-green text-uppercase" onClick={() => handleCheckStatus(envio.codigo_envio, envio.transportista_id)}>Comprobar estado</span>
                                                                }
                                                            </span>
                                                        :
                                                            <div className="w-100">
                                                                <span title="Generar envio pendiente" style={{cursor: 'pointer'}}>
                                                                <FontAwesomeIcon icon={faTruck} size="1x" className="action" color="#215732" onClick={() => handleOpenConfirmEnvioPendiente(envio)} />
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                    : ''
                                                }
                                                { (envio?.estado) && 
                                                    <span className={"fw-bold " + getEstado(envio.transportista_id, envio.estado.codigo)}>
                                                        {envio.estado.nombre}
                                                    </span>
                                                }
                                            </div>
                                        )
                                    })
                                : '-'
                                }
                            </td> : null
                            const cliente = i === 0 ?  <td rowSpan={piezasValues.length + 1}>
                                <div className="d-flex flex-column">
                                <Link target="_blank" rel="noopener noreferrer" to={"/cliente/ficha/" + incidencia.cliente?.id} title="Ficha Cliente" className="text-green text-uppercase">
                                    {incidencia.cliente?.nombre}
                                </Link>
                                <small>{incidencia.cliente.telefono}</small>
                                </div>
                            </td> : null
                            const proveedor = i === 0 ?  <td rowSpan={piezasValues.length + 1}>
                                <div className="d-flex flex-column">
                                <Link target="_blank" rel="noopener noreferrer" to={"/proveedor/ficha/" + incidencia.proveedor?.id} title="Ficha Proveedor" className="text-green text-uppercase">
                                    {incidencia.proveedor?.nombre}
                                </Link>
                                <small>{(incidencia.proveedor?.telefono_incidencias !== "" && incidencia.proveedor?.telefono_incidencias !== null) ? incidencia.proveedor?.telefono_incidencias : incidencia.proveedor?.telefono}</small>
                                </div>
                            </td> : null
                            const responsable = i === 0 ?  <td rowSpan={piezasValues.length + 1}  className="text-center text-uppercase">{incidencia.created_by?.username}</td> : null
                            const creadoPor = i === 0 ?  <td rowSpan={piezasValues.length + 1}  className="text-center text-uppercase">{incidencia.crafted_by?.username}</td> : null
                            const estado = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="8%" className={"text-center text-uppercase"}>
                                <span className={"fw-bold d-block " + ((incidencia.estado_incidencia_id === 1 || incidencia.estado_incidencia_id === 4) ? 'text-danger' : 'text-green')}>{incidencia.estado?.nombre}</span>
                                <small className={"fw-bold d-block " + (incidencia.subestado_incidencia_id === 1 ? 'text-warning' : 'text-info')}>{incidencia.subestado?.nombre}</small>
                            </td> : null
                            const sp = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="5%" className={"text-center " + (incidencia.estado_incidencia_id === 1 ? 'text-danger' : 'text-green')}>
                                { incidencia.solucion_proveedor ? 
                                <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#00986C" />
                                :
                                <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#dc3545" />
                                }
                            </td> : null
                            const sc = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="5%" className={"text-center " + (incidencia.estado_incidencia_id === 1 ? 'text-danger' : 'text-green')}>
                                { incidencia.solucion_cliente ? 
                                <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#00986C" />
                                :
                                <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#dc3545" />
                                }
                            </td> : null
                            const acciones = i === 0 ?  <td rowSpan={piezasValues.length + 1} className="text-center">
                                <ActionsMenu 
                                    options={getActions(incidencia, hasAvisos)}
                                    onAction={(action) => handleAction(action, incidencia)}
                                />
                            </td> : null
                                
                            return (
                                <tr key={i}>
                                    {avisos}
                                    {numero}
                                    {fecha}
                                    {tipo}
                                    {factura}
                                    {cliente}
                                    {proveedor}
                                    <td>
                                        <span className="d-block">{pieza.nombre}</span>
                                        <small>#{pieza.id_referencia_plataforma}</small>
                                    </td>
                                    <td className="text-center" width="6%">{parseFloat(pieza.precio_venta).toFixed(2)}€</td>
                                    {envio}
                                    {responsable}
                                    {creadoPor}
                                    {estado}
                                    {sp}
                                    {sc}
                                    {acciones}
                                </tr>
                            )
                        
                        })
                            return (
                                <tbody key={index}>
                                    {piezasRows}
                                </tbody>
                                )
                        }) 
                        }
                </table>
            </div>
            
            <Pagination 
                id={id}
                className="mt-3" 
                count={getPaginationCount()} 
                page={currPage} 
                onChange={handleChangePage} 
            />

            { openConfirmRegenerarEnvio && 
                <ConfirmModal 
                    onConfirmAction={handleCreateEnvioPendiente} 
                    title={'Regenerar envio pendiente'} 
                    description={'Estás seguro de regenerar el envio pendiente?'}
                    state={openConfirmRegenerarEnvio}>
                </ConfirmModal>
            }
        </div>
    )
}