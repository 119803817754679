import { useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { getAllowedExt } from '../../../shared/helpers/functionalities';

export default function VentaForm({
    onSubmitVenta, 
}) {
    const plataformas = useSelector(state => {
        const { plataformas } = state;
        return plataformas;
    });

    const ventaSchema = yup.object({
        codigo_pedido: yup.string().required(),
        plataforma_id: yup.number().integer().moreThan(0).required(),
      }).required();

    const { register, formState: { errors }, handleSubmit, setValue } = useForm({
        resolver: yupResolver(ventaSchema),
        mode: "onChange",
        defaultValues: {
            plataforma_id: -1,
            codigo_pedido: '',
            comprobante_bancario: null
        }
    });

    useEffect(() => {
      
    }, []);

    const handleChangePlataforma = (e) => setValue('plataforma_id', e.target.value);

    // metodo para guardar la nueva pieza
    const handleSubmitVenta = async (data, e) => {
        e.preventDefault();
        onSubmitVenta(data);
    };

    return (
        <div className="w-100 d-flex flex-column justify-content-center mt-2">
            <form className="form" onSubmit={handleSubmit(handleSubmitVenta)}>
                <div className="form-group">
                    <label htmlFor="plataforma_id">Plataforma</label>
                    { (plataformas && plataformas.length > 0) &&
                        <select 
                            {...register("plataforma_id")}
                            defaultValue={-1}
                            onChange={handleChangePlataforma}>
                            <option key={'marcas-0'} value="">Selecciona</option>
                            {plataformas.map(op => <option key={'plataformas-' + op.id} value={op.id}>{op.nombre}</option>)}
                        </select>
                    }

                    <div className="form-invalid">
                        {errors.plataforma_id?.message && 'Selecciona una plataforma válida.'}
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="codigo_pedido">Código de pedido</label>
                    <input 
                        type="text" 
                        className="form-control"
                        {...register("codigo_pedido", { required: true })} 
                        defaultValue={''} />

                    <div className="form-invalid">
                        {errors.codigo_pedido?.message && 'Introduce un código de pedido'}
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="comprobante_bancario">Adjuntar comprobande bancario</label>
                    <input 
                        type="file" 
                        className="form-control" 
                        accept={getAllowedExt('pdf')}
                        {...register("comprobante_bancario", { maxLength: 255 })} 
                        defaultValue={null}
                    />
                </div>
                
                <div className="d-flex align-items-center align-self-end">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    );
}