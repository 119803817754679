import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import React from "react";
import MultiFileUploadForm from "../../../shared/components/MultiFileUploadForm";
import LightBoxImages from "../../../shared/components/LightBoxImages";

export default function Images({
    devolucion,
    onUpload,
    onDelete
}) {
    const [openImage, setOpenImage] = useState(false);
    const [imagesSRCs, setImagesSRCs] = useState([]);
    const [imageIndex, setImageIndex] = useState(0);
 
    useEffect(() => {
       const imgSRCs = devolucion.imagenes.map((img) => {
        return `data:image/jpeg;base64,${img}`;
       })

       setImagesSRCs(imgSRCs);
    }, [devolucion]);

    const handleOpenLightBox = (index) => {
        setImageIndex(index);
        setOpenImage(true);
    }

    const handleCloseImages = () => {
        setOpenImage(false);
        setImageIndex(null);
    }

    return (
        <div className="w-100 d-flex flex-column">
           { (3 - devolucion.imagenes?.length > 0) &&
            <div className="w-100 mt-3">
                <MultiFileUploadForm 
                    itemId={"files"}
                    labelText={<>Imágenes, <b>máximo {3 - devolucion.imagenes?.length}</b></>}
                    onSubmit={onUpload}
                    maxFilesCount={3 - devolucion.imagenes?.length}
                />
            </div>
            }

            <div className="w-100 d-flex flex-wrap align-items-center justify-content-center justify-content-md-start">
                { devolucion.imagenes.map(({img, nombre}, i) => {
                    return (
                        <div className="d-flex flex-column align-items-center mb-4 me-4" key={'images-' + i}>
                            <img 
                                key={"Imagen " + (i+1)} 
                                src={`data:image/jpeg;base64,${img}`}
                                alt={"Imagen " + (i+1)} 
                                width={'200px'}
                                height={'200px'}
                                style={{cursor: 'pointer', objectFit: 'cover'}}
                                onClick={() => handleOpenLightBox(i)}
                            />

                            <span title="Eliminar" style={{cursor: 'pointer'}} onClick={() => onDelete(nombre)} >
                                <FontAwesomeIcon icon={faTrash} size="1x" className="action mt-2" color="#dc3545"/>
                            </span>
                        </div>
                    )
                })}
            </div>

            { (openImage) &&
                <LightBoxImages
                    images={imagesSRCs}
                    startIndex={imageIndex}
                    pieza={"Devolución mercancía"}
                    onClose={handleCloseImages}
                />
            }
        </div>
    )
}