import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Autocomplete, TextField } from "@mui/material";
import ConfirmModal from "../../../shared/components/ConfirmModal";
import InfoModal from "../../../shared/components/InfoModal";
import SubtipoForm from "./components/SubtipoForm";
import RecicleBin from "./components/RecicleBin";
import {
  addSubtipoIncidencia,
  editSubtipoIncidencia,
} from "../../../shared/services/subtipos_incidencia";
import { fetchSubtiposIncidencia } from "../../../../../../redux/subtiposIncidencia";
import { toast } from "react-toastify";
import "./subtipos.scss";

export default function Subtipos() {
  /** Redux **/
  const dispatch = useDispatch();
  const tipoIncidencias = useSelector((state) => {
    return state.tipo_incidencias;
  });
  const subtiposIncidencia = useSelector((state) => {
    return state.subtipos_incidencia?.filter((subtipo) => subtipo.active);
  });
  const subtiposIncidenciaInactivos = useSelector((state) => {
    return state.subtipos_incidencia?.filter((subtipo) => !subtipo.active);
  });

  /** States **/
  const [currentSubtipo, setCurrentSubtipo] = useState(null);
  const [openRecicleBin, setOpenRecicleBin] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [activeFilter, setActiveFilter] = useState(null);
  const [filteredResults, setFilteredResults] = useState([]);
  const [allSubtiposAreInactive, setAllSubtiposAreInactive] = useState(false);
  const [someSubtiposAreInactive, setSomeSubtipoIsInactive] = useState(false);

  /** Lifecycle **/
  useEffect(() => {
    handleAllSubtiposAreInactive();
    handleSomeSubtiposAreInactive();
    if (activeFilter) {
      const filteredSubtipos = subtiposIncidencia.filter(
        (subtipo) => subtipo.tipo_incidencia_id === activeFilter
      );
      setFilteredResults(filteredSubtipos);
    } else {
      setFilteredResults(subtiposIncidencia);
    }
  }, [subtiposIncidencia, subtiposIncidenciaInactivos]);

  /** Methods **/
  const handleAllSubtiposAreInactive = () => {
    const hasActiveSubtipos = subtiposIncidencia?.some(
      (subtipo) => subtipo.active
    );
    setAllSubtiposAreInactive(!hasActiveSubtipos);
  };

  const handleSomeSubtiposAreInactive = () => {
    const hasInactiveSubtipos = subtiposIncidenciaInactivos.length > 0;
    setSomeSubtipoIsInactive(hasInactiveSubtipos);
  };

  const getTipoIncidenciaName = (tipoIncidenciaId) => {
    return tipoIncidencias.find((f) => f.id === tipoIncidenciaId)?.nombre;
  };

  const handleOpenRecicleBin = () => {
    setOpenRecicleBin(true);
  };

  const handleOpenForm = (subtipo = null) => {
    if (subtipo) {
      subtipo.tipo_incidencia_id =
        subtipo.tipo_incidencia_id === null ? -1 : subtipo.tipo_incidencia_id;
    }

    setCurrentSubtipo(subtipo);
    setOpenForm(true);
  };

  const handleRecicleSubtipo = async (subtipo) => {
    dispatch({ type: "loading/set", payload: true });
    const resp = await editSubtipoIncidencia(subtipo.id, {
      ...subtipo,
      active: true,
    });
    dispatch({ type: "loading/set", payload: false });

    if (resp && resp.success) {
      dispatch(fetchSubtiposIncidencia());
      toast.success(resp.message);
    } else {
      toast.error(resp.message);
    }
  };

  const handleAddSubtipo = async (subtipo) => {
    dispatch({ type: "loading/set", payload: true });
    const resp = await addSubtipoIncidencia(subtipo);
    dispatch({ type: "loading/set", payload: false });

    if (resp && resp.success) {
      dispatch(fetchSubtiposIncidencia());
      handleCloseForm();
      toast.success(resp.message);
    } else {
      toast.error(resp.message);
    }
  };

  const handleEditSubtipo = async (id, subtipo) => {
    dispatch({ type: "loading/set", payload: true });
    const resp = await editSubtipoIncidencia(id, subtipo);
    dispatch({ type: "loading/set", payload: false });

    if (resp && resp.success) {
      dispatch(fetchSubtiposIncidencia());
      handleCloseForm();
      toast.success(resp.message);
    } else {
      toast.error(resp.message);
    }
  };

  const handleClickDelete = async (subtipo) => {
    setCurrentSubtipo(subtipo);
    setOpenConfirm(true);
  };

  const handleConfirmDelete = async (confirm) => {
    if (confirm) {
      dispatch({ type: "loading/set", payload: true });
      const resp = await editSubtipoIncidencia(currentSubtipo.id, {
        ...currentSubtipo,
        active: false,
      });
      dispatch({ type: "loading/set", payload: false });

      if (resp && resp.success) {
        dispatch(fetchSubtiposIncidencia());
        handleCloseConfirm();
        toast.success("Subtipo eliminado correctamente.");
      } else {
        toast.error(resp.message);
      }
    }
  };

  const handleCloseRecicleBin = () => {
    setOpenRecicleBin(false);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setCurrentSubtipo(null);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
    setCurrentSubtipo(null);
  };

  /** Render **/
  return (
    <div className="w-100 d-flex flex-column align-items-start">
      <div className="w-100 d-flex flex-wrap flex-md-nowrap justify-content-center">
        <div className="col-12 col-md-8 d-flex flex-column align-items-end order-1 order-md-0">
          <div className="w-100 d-flex align-items-center justify-content-between mb-4">
            <div id="subtipos-autocomplete">
              <Autocomplete
                id="subtipos-tipo"
                options={tipoIncidencias}
                getOptionLabel={(option) => option.nombre}
                onChange={(_event, newValue) => setActiveFilter(newValue?.id)}
                autoHighlight
                renderInput={(params) => <TextField {...params} label="Tipo" />}
              />
            </div>
            <div className="d-flex gap-4">
              {someSubtiposAreInactive && (
                <button
                  className="btn btn-add"
                  onClick={() => handleOpenRecicleBin()}
                >
                  <span className="add">
                    <FontAwesomeIcon
                      icon={faTrash}
                      size="1x"
                      className="action"
                      color="#215732"
                    />
                  </span>
                  Papelera
                </button>
              )}
              <button className="btn btn-add" onClick={() => handleOpenForm()}>
                <span className="add">
                  <FontAwesomeIcon
                    icon={faPlus}
                    size="1x"
                    className="action"
                    color="#215732"
                  />
                </span>
                Nuevo
              </button>
            </div>
          </div>

          <div className="w-100 table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-center" style={{ width: "80px" }}>
                    ID
                  </th>
                  <th>Nombre</th>
                  <th className="text-center" style={{ width: "200px" }}>
                    Tipo de incidéncia
                  </th>
                  <th className="text-center" style={{ width: "80px" }}>
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredResults?.map((subtipo) => {
                  return (
                    <tr
                      key={"valor-" + subtipo.id}
                      style={{ borderBottom: "12px solid white" }}
                    >
                      <td className="text-center">{subtipo.id}</td>
                      <td>{subtipo.nombre}</td>
                      <td className="text-center text-uppercase">
                        {getTipoIncidenciaName(subtipo.tipo_incidencia_id) ||
                          "-"}
                      </td>
                      <td className="text-center" width="10%">
                        <span
                          title="Editar"
                          style={{ cursor: "pointer" }}
                          className="me-3"
                        >
                          <FontAwesomeIcon
                            icon={faPen}
                            size="1x"
                            className="action"
                            color="#215732"
                            onClick={() => handleOpenForm(subtipo)}
                          />
                        </span>
                        <span title="Eliminar" style={{ cursor: "pointer" }}>
                          <FontAwesomeIcon
                            icon={faTrash}
                            size="1x"
                            className="action"
                            color="#dc3545"
                            onClick={() => handleClickDelete(subtipo)}
                          />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {(filteredResults.length === 0 || allSubtiposAreInactive) && (
              <p className="w-100 text-center border-top pt-3">
                No se encontraron resultados
              </p>
            )}
          </div>
        </div>
      </div>

      {openForm && (
        <InfoModal
          content={
            <SubtipoForm
              subtipo={currentSubtipo}
              isEdit={currentSubtipo ? true : false}
              onCancel={handleCloseForm}
              onAdd={handleAddSubtipo}
              onEdit={handleEditSubtipo}
            />
          }
          onClose={handleCloseForm}
          state={openForm}
          title={(currentSubtipo ? "Editar" : "Nuevo") + " subtipo"}
          width={"md"}
        />
      )}

      {openRecicleBin && (
        <InfoModal
          content={
            <RecicleBin
              subtiposIncidenciaInactivos={subtiposIncidenciaInactivos}
              getTipoIncidenciaName={getTipoIncidenciaName}
              onCancel={handleCloseRecicleBin}
              onRecicle={handleRecicleSubtipo}
            />
          }
          onClose={handleCloseRecicleBin}
          state={openRecicleBin}
          title={"Papelera"}
          width={"md"}
        />
      )}

      {openConfirm && (
        <ConfirmModal
          description={"Estás seguro de eliminar?"}
          onClose={() => setOpenConfirm(false)}
          onConfirmAction={handleConfirmDelete}
          state={openConfirm}
          title={"Eliminar"}
        />
      )}
    </div>
  );
}
