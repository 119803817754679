import React, { useEffect, useState } from "react";
import PlacesAutocomplete, 
{ geocodeByAddress, geocodeByPlaceId } from "react-places-autocomplete";
import { toast } from "react-toastify";
import './autocomplete.scss';


export default function GooglePlacesAutocomplete({
    type,
    types,
    defaultValue,
    country,
    disabled,
    onSelectResult
}) {
    const [selected, setSelected] = useState('');

    useEffect(() => {
      if(defaultValue) {
        setSelected(defaultValue);
      }
    }, [country, defaultValue, disabled]);

    const handleSelectPlace = (place) => {
        setSelected(place);
    }

    const handleSelect = async (address, placeId) => {
      let result = null;
      const [place] = await geocodeByPlaceId(placeId);
      const [results] = await geocodeByAddress(address)
      
      const {long_name: extra = ''} = place.address_components.find(c => c.types.includes('subpremise')) || {};
      const {long_name: num = null} = place.address_components.find(c => c.types.includes('street_number')) 
        || results.address_components.find(c => c.types.includes('street_number')) || {};
      const {long_name: street = null} = place.address_components.find(c => c.types.includes('route')) 
        || results.address_components.find(c => c.types.includes('route')) || {};
      const {long_name: postalCode = null} = place.address_components.find(c => c.types.includes('postal_code')) 
        || results.address_components.find(c => c.types.includes('postal_code')) || {};
      const {long_name: city = null} = place.address_components.find(c => c.types.includes('locality'))
        || results.address_components.find(c => c.types.includes('locality')) 
        || place.address_components.find(c => c.types.includes('administrative_area_level_3')) 
        || place.address_components.find(c => c.types.includes('sublocality')) || {};
      const {long_name: adminArea = null} = place.address_components.find(c => c.types.includes('administrative_area_level_1')) 
        || results.address_components.find(c => c.types.includes('administrative_area_level_1')) || {};
      const {long_name: adminArea2 = null} = place.address_components.find(c => c.types.includes('administrative_area_level_2'))
        || results.address_components.find(c => c.types.includes('administrative_area_level_2')) 
        || place.address_components.find(c => c.types.includes('administrative_area_level_3')) 
        || place.address_components.find(c => c.types.includes('locality')) 
        || {};
      const {long_name: countryName = null} = place.address_components.find(c => c.types.includes('country')) 
        || results.address_components.find(c => c.types.includes('country')) || {};

      if(type === 'address') {
        if(!street || !postalCode || !city || !adminArea2 || !num) {
          if(!num && !postalCode) {
            toast.warning('El número de calle es obligatorio para esta dirección');
            return;
          } else {
            if((!street || !postalCode || !adminArea2) && country !== 'HU') {
              toast.warning('Faltan datos en la dirección');
              return;
            }
          }
        }
        result = {
          direccion: place.types.find(t => t === 'establishment') ? `${street}, ${num} ${extra}` : selected,
          codigo_postal: postalCode,
          poblacion: city,
          area: `${adminArea2 || city || countryName}, ${adminArea || countryName}`
        }
      } else {
        if(!postalCode || !city || !adminArea2) {
          if(!adminArea2) {
            if(!adminArea) {
              toast.warning('No hay provincia asignada');
              return;
            }
          } else {
            toast.warning('No hay código postal');
            return;
          }
        }
        result = {
          codigo_postal: postalCode,
          poblacion: city,
          provincia: adminArea2 ? adminArea2 : adminArea,
          area: adminArea
        }
      }
      onSelectResult(result)
    };

    return (
        <PlacesAutocomplete
          value={selected}
          searchOptions={{
            types,
            language: 'es',
            componentRestrictions: {
              country: [country],
            }
          }}
          onChange={handleSelectPlace}
          onSelect={handleSelect}
          onError={(status, clearSuggestions) => {
            if(status === 'ZERO_RESULTS') {
              console.log('No hay resultados');
              toast.warning('No hay resultados');
            } else {
              console.log('Google Maps API returned error with status: ', status)
              toast.error('Google Maps API returned error with status: ', status)
            }
            
            clearSuggestions()
          }}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div className="places-search">
              <input
                {...getInputProps({
                  placeholder: 'Busca una dirección ...',
                  className: 'form-control',
                  disabled: disabled,
                })}
              />
              <div className="places-search-container">
                {loading && <div className="suggestion-item">Loading...</div>}
                {suggestions.map((suggestion, i) => {
                  const className = suggestion.active
                    ? 'suggestion-item-active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                      key={'place-suggestion-' + i}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      );
}