import API from '../../../../../services/api';
import { headers } from '../helpers/functionalities';

export const getMensajes = async () => {
    return await API.get('/mensajes').then(res => res.data);
}

export const newMensaje = async (mensaje) => {
    return API.post('/mensajes', mensaje, headers).then(res => res.data);
}

export const updateMensaje = async (idMensaje, mensaje) => {
    return API.post('/mensajes/' + idMensaje + '/update', mensaje).then(res => res.data);
}

export const enableDisableMensaje = async (mensaje) => {
    return API.post('/mensajes/' + mensaje.id +'/status', mensaje).then(res => res.data);
}

export const deleteMensaje = async (idMensaje) => {
    return API.delete('/mensajes/' + idMensaje).then(res => res.data);
}