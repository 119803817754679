import { useEffect, useState } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import ConfirmModal from "../../../shared/components/ConfirmModal";
import StepForm from "./components/StepForm";
import { deleteStep } from "../../../shared/services/incidenciasSteps";
import { fetchSteps } from "../../../../../../redux/incidenciaSteps";
import InfoModal from '../../../shared/components/InfoModal';
import { Autocomplete, TextField } from '@mui/material';
import './steps.scss';

export default function Steps() {
    const dispatch = useDispatch();
    const [stepId, setStepId] = useState(null);
    const [currentStep, setCurrentStep] = useState(null);
    const [isForDelete, setIsForDelete] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [filteredResults, setFilteredResults] = useState([])
    const [activeFilters, setActiveFilters] = useState({
        tipo: '',
        lista: ''
    })

    const tipoIncidencias = useSelector(state => {
        const { tipo_incidencias } = state;
        return tipo_incidencias;
    });
    const steps = useSelector(state => {
        return state.steps
    });

    useEffect(() => {
      handleFilterBy()
    }, [steps, activeFilters])
    
    const handleOpenForm = (step = null) => {
        if(step) {
            step.tipo_incidencia_id = step.tipo_incidencia_id === null ? -1 : step.tipo_incidencia_id;
        }

        setCurrentStep(step);
        setOpenForm(true);
    }

    const handleCloseForm = () => { 
        setOpenForm(false);
        setCurrentStep(null);
    }

    const handleActionDone = () => {
        handleCloseForm();
        dispatch(fetchSteps());
    }

    const handleDeleteElement = async(stepId) => {
        dispatch({type: 'loading/set', payload: true});
        const response = await deleteStep(stepId);
        setCurrentStep(null);

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
            dispatch(fetchSteps());
        }
    }

    const handleDelete = (id) => { 
        setStepId(id);
        setIsForDelete(true); 
    }

    const handleConfirmDelete = (confirm) => {
        if(confirm) {
            handleDeleteElement(stepId)
        } else setIsForDelete(false);
    }

    const getTipoIncidencia = (tipoIncidenciaId) => { return tipoIncidencias.find(f => f.id === tipoIncidenciaId)?.nombre }

    const handleFilters = (newValue, filterBy) => {
        const filter = filterBy === 'tipo' ? newValue?.id : newValue;
        setActiveFilters({
            ...activeFilters,
            [filterBy]: filter
        })
    }

    const handleFilterBy = () => {
        if(activeFilters.tipo && activeFilters.lista) {
            return setFilteredResults(steps.filter(
                step => step.tipo_incidencia_id === activeFilters.tipo 
                            && step.owner === activeFilters.lista))
        } else if (activeFilters.tipo && !activeFilters.lista) {
            return setFilteredResults(steps.filter(
                step => step.tipo_incidencia_id === activeFilters.tipo))
        } else if (!activeFilters.tipo && activeFilters.lista) {
            return setFilteredResults(steps.filter(
                step => step.owner === activeFilters.lista))
        }
        return setFilteredResults(steps)
    }

    return (
        <div className="w-100 d-flex flex-column align-items-start"> 
            <div className="w-100 d-flex flex-wrap flex-md-nowrap justify-content-center">
                <div className="col-12 col-md-8 d-flex flex-column align-items-end order-1 order-md-0">
                    <div className="w-100 d-flex align-items-center justify-content-between mb-4">
                        <div id='steps-autocomplete'>
                            <Autocomplete
                                id='steps-tipo'
                                options={tipoIncidencias}
                                getOptionLabel={(option) =>  option.nombre}
                                onChange={(event, newValue) => {
                                    handleFilters(newValue, 'tipo');
                                  }}
                                autoHighlight
                                renderInput={(params) => (
                                    <TextField {...params} label="Tipo" />
                                    )}
                                />
                            <Autocomplete
                                id='steps-tipo'
                                options={['cliente', 'proveedor']}
                                getOptionLabel={(option) =>  option}
                                onChange={(event, newValue) => {
                                    handleFilters(newValue, 'lista');
                                  }}
                                autoHighlight
                                renderInput={(params) => (
                                    <TextField {...params} label="Lista" />
                                    )}
                            
                                />
                        </div>
                        <button className="btn btn-add" onClick={() => handleOpenForm()}>
                            <span className="add">
                                <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                            </span>
                            Nuevo
                        </button>
                    </div>

                    <div className="w-100 table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="text-center">Posición</th>
                                    <th>Nombre</th>
                                    <th className="text-center">Tipo</th>
                                    <th className="text-center">Lista</th>
                                    <th className="text-center">Solución</th>
                                    <th className="text-center">Respuestas</th>
                                    <th className="text-center">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                { filteredResults?.map((step, i) => {
                                    const clientes = steps.filter(f => f.owner === 'cliente')?.length;

                                    return (
                                        <tr key={'valor-' + step.id} style={clientes === i ? { borderTop: '2px solid #000'} : (clientes - 1) === i ? { borderBottom: 'none'} : {}}>
                                            <td className="text-center">{step.step}</td>
                                            <td>{step.nombre}</td>
                                            <td className="text-center text-uppercase">{getTipoIncidencia(step.tipo_incidencia_id) || '-'}</td>
                                            <td className="text-center text-uppercase" width="10%">{step.owner}</td>
                                            <td className="text-center" width="10%">
                                                { step?.solucion ?
                                                    <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#215732" />
                                                    :
                                                    ''
                                                }
                                            </td>
                                            <td className="text-center">{step?.respuestas?.length}</td>
                                            <td className="text-center" width="10%">
                                                <span title="Editar" style={{cursor: 'pointer'}} className="me-3">
                                                    <FontAwesomeIcon icon={faPen} size="1x" className="action" color="#215732" onClick={() => handleOpenForm(step)} />
                                                </span>
                                                <span title="Eliminar" style={{cursor: 'pointer'}}>
                                                    <FontAwesomeIcon icon={faTrash} size="1x" className="action" color="#dc3545" onClick={() => handleDelete(step.id)} />
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            { (openForm) && 
                <InfoModal
                    state={openForm}
                    title={(currentStep ? 'Editar' : 'Nueva') + ' paso'}
                    content={
                        <StepForm 
                            step={currentStep}
                            onCancel={handleCloseForm}
                            onActionDone={handleActionDone}
                        />
                    }
                    width={'md'}
                    onClose={handleCloseForm}
                ></InfoModal>
            }

            { isForDelete && 
                <ConfirmModal
                    onClose={handleConfirmDelete}
                    onConfirmAction={handleConfirmDelete} 
                    title={'Eliminar'} 
                    description={'Estás seguro de eliminar?'}
                    state={isForDelete}
                >
                </ConfirmModal>
            }
        </div>
    )
    
}