import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

export default function StepForm({ subtipo, isEdit, onCancel, onAdd, onEdit }) {
  /** Redux **/
  const tipoIncidencias = useSelector((state) => {
    return state.tipo_incidencias;
  });

  /** Form **/
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      id: 0,
      nombre: "",
      tipo_incidencia_id: -1,
    },
  });

  /** Lifecycle **/
  useEffect(() => {
    reset(subtipo);
  }, [subtipo]);

  /** Methods **/
  const handleChangeTipoIncidencia = (e) => {
    setValue("tipo_incidencia_id", parseInt(e.target.value));
  };

  const handleSubmitForm = (formData) => {
    if (isEdit) {
      const id = formData.id;
      const data = {
        nombre: formData.nombre,
        tipo_incidencia_id: formData.tipo_incidencia_id,
        active: true,
      };
      onEdit(id, data);
    } else {
      const data = {
        nombre: formData.nombre,
        tipo_incidencia_id: formData.tipo_incidencia_id,
      };
      onAdd(data);
    }
  };

  const handleCancel = () => onCancel();

  /** Render **/
  return (
    <div className="w-100 d-flex flex-column">
      <form
        className="form align-items-center mb-4"
        onSubmit={handleSubmit(handleSubmitForm)}
      >
        <input
          type="hidden"
          {...register("id")}
          defaultValue={subtipo ? subtipo.id : ""}
        />

        <div className="w-100 d-flex flex-column flex-grow-1">
          <div className="form-group">
            <label htmlFor="nombre">Nombre</label>
            <input
              type="text"
              className="form-control"
              {...register("nombre", { required: true })}
              defaultValue={subtipo ? subtipo.nombre : 0}
            />

            <div className="form-invalid">
              {errors.nombre?.type === "required" && "Nombre es obligatorio"}
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="tipo_incidencia_id">Tipo de incidéncia</label>
            {tipoIncidencias && tipoIncidencias.length > 0 && (
              <select
                {...register("tipo_incidencia_id", { required: true })}
                defaultValue={
                  subtipo ? parseInt(subtipo.tipo_incidencia_id) : -1
                }
                onChange={handleChangeTipoIncidencia}
              >
                <option key={"tipo-incidencias-0"} value={-1}>
                  Seleciona un tipo de incidencia
                </option>
                {tipoIncidencias.map((tipoIncidencia) => (
                  <option
                    key={"tipo-incidencias-" + tipoIncidencia.id}
                    value={tipoIncidencia.id}
                  >
                    {tipoIncidencia.nombre}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>

        <div className="d-flex align-items-center align-self-end ms-3 mt-4">
          <button
            type="submit"
            className="btn btn-primary align-self-end"
            disabled={parseInt(watch("tipo_incidencia_id")) === -1}
          >
            Guardar
          </button>
          <button
            type="button"
            className="btn btn-default align-self-end ms-3"
            onClick={handleCancel}
          >
            Cancelar
          </button>
        </div>
      </form>
    </div>
  );
}
