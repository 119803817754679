import { useState, useEffect } from "react";
import React from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEuroSign } from "@fortawesome/free-solid-svg-icons";
import VirtualizedAutocomplete from "../../../../../../../../../../../shared/components/Autocomplete";
import { getAllowedExt, getGarantia } from "../../../../../../../../../../../shared/helpers/functionalities";

export default function PiezaForm({
    form,
    pieza, 
    proveedor,
    tarifaClienteId,
    isEditing,
    esVenta,
    cancel,
    onSubmitPieza, 
    onCancel,
    onChangePrecioEnvioProveedor
}) {
    const { register, formState: { errors }, handleSubmit, reset, control, setValue, getValues } = form;
    const presupuesto = useSelector(state => {
        return state.presupuesto.entity
    })
    const familiaPiezas = useSelector(state => {
        const { familia_piezas } = state;
        return familia_piezas;
    });
    const tipoPiezas = useSelector(state => {
        const { tipo_piezas } = state;
        return tipo_piezas;
    });
    const tipoGarantias = useSelector(state => {
        const { tipo_garantias } = state;
        return tipo_garantias;
    });
    const marcas = useSelector(state => {
        const { marcas } = state;
        return marcas;
    });
    const modelos = useSelector(state => {
        const { modelos } = state;
        return modelos;
    });
    const tarifas = useSelector(state => {
        const { tarifas } = state;
        return tarifas;
    });

    const currentUser = JSON.parse(localStorage.getItem('user'));
    const [ filterTipoPiezas, setFilterTipoPiezas ] = useState(tipoPiezas);
    const [ filterModelos, setFilterModelos ] = useState(modelos);
    const [ isVenta, setIsVenta] = useState(esVenta || false);
    const [ servicioEnvio, setServicioEnvio] = useState(null);
    let timeout = null;

    useEffect(() => {
        if(pieza) {
            let tipo = tipoPiezas.find(f => f.id === parseInt(pieza.tipo_pieza_id));

            if(tipo) {
                const familia = familiaPiezas.find(f => f.id === tipo.familia_tipo_pieza_id);
                if(familia) pieza.familia_tipo_pieza_id = familia.id;
            }

            // SI HA ENCONTRADO LA MARCA PERO NO EL MODELO, SOLO MOSTRAMOS MODELOS DE LA MARCA ENCONTRADA
            if((pieza.modelo_id !== null || pieza.modelo_id !== "" ) && pieza.marca_id !== null) {
                const models = modelos.filter(f => f.marca_id === parseInt(pieza.marca_id));
                setFilterModelos([...models]);
                let model = null;
                if(typeof pieza.modelo_id  === "string" ) {
                    model = models.find(f => f.nombre?.toLowerCase().includes(pieza.modelo_id?.toLowerCase()));
                } else {
                    model = models.find(f => f.id === parseInt(pieza.modelo_id));
                }
                
                if(model) {
                    pieza.modelo_id = model.id;
                    pieza.modelo = model;
                } else model = null;
            } else if ((pieza.modelo_id === null || pieza.modelo_id === "" ) && pieza.marca_id !== null) {
                const models = modelos.filter(f => f.marca_id === pieza.marca_id);
                setFilterModelos([...models]);

            } else {
                setFilterModelos([...modelos]);
            }

            if(!isEditing) {
                if(pieza.precio_original && pieza.precio_original > 0) {
                    const precios = calcularPrecios(pieza);

                    if(pieza.proveedor.id !== 409) {
                        if(!pieza.precio_venta || pieza.precio_venta === 0) {
                            pieza.precio_compra = precios.compra;
                            pieza.descuento = precios.descuento;
                            pieza.casco = 0;
                            pieza.tarifa_valor = precios.tarifa.valor;
                            pieza.tarifa_tipo = precios.tarifa.tipo;
                            pieza.precio_transporte = getValues('precio_transporte');
                            pieza.precio_venta = precios.venta;
                        }
                    } else {
                        pieza.descuento = precios.descuento;
                        pieza.casco = 0;
                        pieza.tarifa_valor = precios.tarifa.valor;
                        pieza.tarifa_tipo = precios.tarifa.tipo;
                    }
                }
            } else {
                const precios = calcularPrecios(pieza);

                if(precios.descuento !== pieza.descuento) {
                    pieza.precio_compra = precios.compra;
                    pieza.descuento = precios.descuento;
                    pieza.tarifa_valor = precios.tarifa.valor;
                    pieza.tarifa_tipo = precios.tarifa.tipo;
                    pieza.precio_venta = precios.venta;
                }
            }

            reset(pieza);
            setValue('proveedor_id', proveedor.id);
            setValue('proveedor', proveedor);
            setServicioEnvio(pieza?.servicio_envio ? pieza.servicio_envio : null)
        } else {
            setValue('proveedor_id', proveedor.id);
            setValue('proveedor', proveedor);
        }
    }, [pieza, proveedor]);

    // metodo para guardar la nueva pieza
    const handleSubmitPieza = async (data, e) => {
        e.preventDefault();
        data.modelo_id = data.modelo.id;
        
        if(data.proveedor.id === 409) {
            data.tarifa_valor = 0;
            data.tarifa_tipo = "";
        }

        delete data.modelo;
        let dataAux = null;

        if(data.imagenes && data.imagenes?.length > 0) {
            dataAux = new FormData();
            Object.entries(data).forEach((value) => {
                if((value[0] === 'imagenes')) {
                    if(value[1]?.length > 0) {
                        if(value[1]?.length > 1) {
                            dataAux.append(value[0], value[1])
                        } else {
                            dataAux.append(value[0], value[1][0])
                        }
                    }
                } else { dataAux.append(value[0], value[1]) }
            });
        } else {
            dataAux = data;
        }

        onSubmitPieza(dataAux);
    };

    const handleCancel = () => { onCancel(true); }

    const handleChangeGarantia = (e) => {  setValue('tipo_garantia_id', parseInt(e.target.value));  }

    const handleChangeTipo = (e) => { 
        const value =  parseInt(e.target.value);
        setValue('tipo_pieza_id', value);
        setValue('tipo_garantia_id', getGarantia(value))
    }

    const handleChangeFamilia = (e) => {
        const ftipos = tipoPiezas.filter(f => f.familia_tipo_pieza_id === parseInt(e.target.value));
        setFilterTipoPiezas([...ftipos]);
        setValue('tipo_pieza_id', -1);
    }

    const handleChangeMarca = (e) => {
        setValue('marca_id', parseInt(e.target.value));
        const fmodelos = modelos.filter(f => f.marca_id === parseInt(e.target.value));
        setFilterModelos([...fmodelos]);
    }

    const handleChangeModelo = (value) => {
        if(value) {
            setValue('modelo', value);
            setValue('modelo_id', value.id);
        } else {
            setValue('modelo', null);
            setValue('modelo_id', 0);
        }
    }

    const handleCalcularPrecios = (e) => {
        if (e.key === "Tab") {
            e.preventDefault();
            const precios = calcularPrecios(getValues());

            setValue('precio_compra',  precios.compra);
            setValue('descuento', precios.descuento)
            setValue('tarifa_valor', precios.tarifa.valor)
            setValue('tarifa_tipo', precios.tarifa.tipo)

            if(proveedor.id !== 409) {
                setValue('precio_venta', precios.venta);
            } else {
                setValue('precio_venta', getValues('precio_original'));
            }
        }
    }

    const calcularPrecios = (pieza) => {
        const original = pieza.precio_original;
        let venta = original;
        let compra = original;
        let tarifaId;

        //primero aplicamos el descuento
        let descuentosProveedor;
        let descuento = null;

        if(pieza.con_descuento) {            
            descuentosProveedor = proveedor?.descuentos.length > 0 ? proveedor?.descuentos.sort((a, b) => b.tipo_piezas?.length - a?.tipo_piezas.length) : proveedor?.descuentos;
            descuento = descuentosProveedor?.find(f => {
                let isIn = true;
    
                if(f.tipo_piezas && f.tipo_piezas.length > 0 ) {
                    let tipo = tipoPiezas.find(tp => tp.id === parseInt(pieza.tipo_pieza_id));
                    isIn = tipo ? f.tipo_piezas.find(f => f.id === parseInt(pieza.tipo_pieza_id)) : false;
                }
    
                if(f.max !== null && f.min !== null) {
                    return (parseInt(original) <= f.max && parseInt(original) >= f.min && isIn)
                } else if(f.min !== null && f.max === null) {
                    return (parseInt(original) >= f.min && isIn)
                } else if(f.max !== null && f.min === null) {
                    return (parseInt(original) <= f.max && isIn)
                } else return true;
            });

            if(descuento) descuento = descuento.descuento
        } 
       
        if(descuento) {
            let descuentoCompra = (descuento * parseFloat(original)) / 100;
            compra = parseFloat(original) - descuentoCompra;
            setValue('descuento', descuento);
        } else descuento = 0;

        // ahora calculamos la tarifa
        if(!tarifaClienteId) {
            tarifaId = presupuesto.cliente?.tarifa_cliente_id;
        } else tarifaId = tarifaClienteId;

        const valor = getTarifaValor(compra, tarifaId);
    
        if(valor) {
            if(valor.tipo === 'porcentage') {
                let porcentageValue = "1." + (valor.valor < 10 ? ('0' + valor.valor) : valor.valor);
                porcentageValue = parseFloat(porcentageValue);
                venta = parseFloat(compra) * porcentageValue;
            } else {
                venta = parseFloat(compra) + valor.valor;
            }
        }

        if(descuento === 20) {
            if(compra <= 60) venta = original;
        }
        
        compra = parseFloat(compra).toFixed(2);
        venta = parseFloat(venta).toFixed(2);

        return { 
            compra: compra, 
            venta: venta, 
            descuento: descuento,
            tarifa: { valor: valor?.valor, tipo: valor?.tipo }
        };
    }

    const getTarifaValor = (precioCompra, tarifaId) => {
        const tarifa = tarifas.find(f => f.id === tarifaId);
        const valor = tarifa?.valores.find(f => {
            if(f.max) {
                return parseInt(precioCompra) <= f.max && parseInt(precioCompra) >= f.min 
            } else return parseInt(precioCompra) >= f.min 
        });

        return valor;
    }

    const startTimeOut = (e) =>  timeout = setTimeout(() => { 
        timeout = null;
        handleChangePrecioTransporte(e);
    }, 1000);
    const clearTimeOut = () => timeout ? clearTimeout(timeout) : null;

    const handleChangePrecioTransporte = (e) => {
        const precioTransporte = e.target.value;
        setValue('precio_transporte', precioTransporte);

        if(pieza?.servicio_envio) {
            if(pieza.servicio_envio.transportista_id === 1 && pieza.servicio_envio.servicio_envio_id === 1) {
                onChangePrecioEnvioProveedor(getValues());
            }
        }
    }

    return (
        <div className="w-100 d-flex flex-column justify-content-center mt-2">
            <form className="form" onSubmit={handleSubmit(handleSubmitPieza)}>
                <input 
                    type="hidden" 
                    {...register("id")} 
                    defaultValue={isEditing ? pieza?.id : ''} />
                <input 
                    type="hidden" 
                    {...register("con_descuento")} 
                    defaultValue={isEditing ? pieza?.con_descuento : 1} />

                <input 
                    type="hidden" 
                    {...register("disponible")} 
                    defaultValue={isEditing ? pieza?.disponible : 1} />  

                <input 
                    type="hidden" 
                    {...register("tarifa_valor")} 
                    defaultValue={isEditing ? pieza?.tarifa_valor : ''} />   
                <input 
                    type="hidden" 
                    {...register("tarifa_tipo")} 
                    defaultValue={isEditing ? pieza?.tarifa_tipo : ''} /> 
                 <input 
                    type="hidden" 
                    {...register("integraciones")} 
                    defaultValue={isEditing ? pieza?.integraciones : 0} />  
                <input 
                    type="hidden" 
                    {...register("pieza_integracion_id")} 
                    defaultValue={isEditing ? pieza?.pieza_integracion_id : ''} /> 
                <input 
                    type="hidden" 
                    {...register("proveedor_id")} 
                    defaultValue={isEditing ? pieza?.proveedor_id : ''} />
                <input 
                    type="hidden" 
                    {...register("integracion_json")} 
                    defaultValue={isEditing ? pieza?.integracion_json : ''} />
                

                <div className="w-100 d-flex flex-wrap flex-md-nowrap">
                    <div className="col-12 col-md-6 d-flex flex-column pe-0 pe-md-3">
                        <div className="d-flex align-items-center">
                            <div className="form-group">
                                <label htmlFor="url">URL</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    {...register("url", { required: true, maxLength: 255 })} 
                                    disabled={isVenta}
                                    maxLength="255"
                                    defaultValue={isEditing ? pieza?.url : ''} />
                                
                                <div className="form-invalid">
                                    {errors.url?.message && "La URL es obligatoria"}
                                </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center">
                            <div className="form-group">
                                <label htmlFor="nombre">Nombre</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    disabled={isVenta}
                                    {...register("nombre", { required: true, maxLength: 150 })} 
                                    defaultValue={isEditing ? pieza?.nombre : ''} />
                                
                                <div className="form-invalid">
                                    {errors.nombre?.message && "Nombre es obligatorio"}
                                </div>
                            </div>
                        </div>
                        
                        <div className="d-flex flex-column flex-lg-row align-items-end">
                            <div className="form-group me-0 pe-lg-3">
                                <label htmlFor="unidades">Unidades</label>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    {...register("unidades", { required: true })} 
                                    disabled={isVenta}
                                    defaultValue={(isEditing && presupuesto?.id) ? pieza.presupuesto_relationship.unidades : (pieza?.unidades || 1)} />
                                
                                <div className="form-invalid">
                                    {errors.unidades?.message && "Unidades es obligatorio"}
                                </div>
                            </div>

                            <div className="form-group flex-grow-1">
                                <label htmlFor="precio_transporte">Casco (sin IVA)</label>
                                <div className="position-relative">
                                    <input 
                                        type="number" 
                                        step="0.01"
                                        className="form-control" 
                                        disabled={isVenta}
                                        {...register("casco")} 
                                        min={0}
                                        defaultValue={isEditing ? (pieza?.casco !== null ? pieza?.casco : 0) : 0} />
                                    <FontAwesomeIcon icon={faEuroSign} color="#215732" className="icon" />
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-column flex-lg-row align-items-end">
                            <div className="form-group me-0 pe-lg-3">
                                <label htmlFor="id_referencia_plataforma">ID Referencia plataforma</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    disabled={isVenta}
                                    {...register("id_referencia_plataforma", { required: true, maxLength: 50 })} 
                                    defaultValue={isEditing ? pieza?.id_referencia_plataforma : ''} />
                                
                                <div className="form-invalid">
                                    {errors.id_referencia_plataforma?.message && "ID Referencia Plataforma es obligatorio"}
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="referencia_pieza">Referencia pieza</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    disabled={isVenta}
                                    {...register("referencia_pieza", { required: true, maxLength: 50 })} 
                                    defaultValue={isEditing ? pieza?.referencia_pieza : ''} />
                                
                                <div className="form-invalid">
                                    {errors.referencia_pieza?.message && 'Obligatorio poner la referencia correcta de la pieza para construir una base de datos fiable.'}
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-column flex-lg-row align-items-end">
                            <div className="form-group me-0 pe-lg-3">
                                <label htmlFor="marca_id">Marca</label>
                                { (marcas && marcas.length > 0) &&
                                    <select 
                                        {...register("marca_id")}
                                        defaultValue={isEditing ? pieza?.marca_id : ""}
                                        disabled={isVenta}
                                        onChange={handleChangeMarca}>
                                        <option key={'marcas-0'} value="">Selecciona</option>
                                        {marcas.map(op => <option key={'marcas-' + op.id} value={op.id}>{op.nombre}</option>)}
                                    </select>
                                }

                                <div className="form-invalid">
                                    {errors.marca_id?.message && 'Selecciona una marca válida.'}
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="modelo_id">Modelo</label>

                                <input type="hidden" 
                                    {...register("modelo_id")} 
                                    defaultValue={isEditing ? pieza?.modelo_id : 0} />

                                {
                                    modelos.length > 0 &&
                                    <VirtualizedAutocomplete
                                        id={"modelo"}
                                        control={control}
                                        options={filterModelos.sort((a, b) => a.marca_id - b.marca_id)}
                                        groupBy={"marca"}
                                        disabled={isVenta}
                                        labels={['marca', 'nombre']}
                                        defaultValue={isEditing ? pieza?.modelo : null} 
                                        onChange={handleChangeModelo}
                                    />
                                }

                                <div className="form-invalid">
                                    {errors.modelo_id?.message && 'Selecciona una modelo válido.'}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 d-flex flex-column ps-0 ps-md-3">
                        <div className="d-flex flex-column flex-lg-row align-items-center">
                            <div className="form-group flex-grow-1 me-0 pe-lg-3">
                               <label htmlFor="familia_tipo_pieza_id">Familia</label>
                                { (familiaPiezas && familiaPiezas.length > 0) &&
                                    <select 
                                        {...register("familia_tipo_pieza_id")}
                                        disabled={isVenta}
                                        defaultValue={isEditing ? pieza?.familia_tipo_pieza_id : 1}
                                        onChange={handleChangeFamilia}>
                                        {familiaPiezas.map(op => <option key={'familias-' + op.id} value={op.id}>{op.nombre}</option>)}
                                    </select>
                                }
                            </div>

                            <div className="form-group flex-grow-1">
                                <label htmlFor="tipo_pieza_id">Tipo de pieza</label>
                                { (filterTipoPiezas) &&
                                    <select 
                                        {...register("tipo_pieza_id", { required: true })}
                                        defaultValue={isEditing ? pieza?.tipo_pieza_id : -1}
                                        disabled={isVenta}
                                        onChange={handleChangeTipo}>
                                        <option key={'tipos-0'} value={-1}>Selecciona</option>
                                        {filterTipoPiezas.map(op => <option key={'tipos-' + op.id} value={op.id}>{op.nombre}</option>)}
                                    </select>
                                }

                                <div className="form-invalid">
                                    {errors.tipo_pieza_id?.message && 'Selecciona un tipo de pieza válido.'}
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-column flex-lg-row align-items-end">
                            <div className="form-group flex-grow-1 me-0 pe-lg-3">
                                <label htmlFor="descuento">Descuento Proveedor</label>
                                <div className="position-relative">
                                    <input 
                                        type="text" 
                                        readOnly
                                        className="form-control" 
                                        {...register("descuento")} />
                                    <span className="text-success icon">
                                        <b>%</b>
                                    </span>
                                </div>
                            </div>

                            <div className="form-group flex-grow-1">
                                <label htmlFor="tipo_garantia_id">Tipo de garantía</label>
                                { (tipoGarantias) &&
                                    <select 
                                        {...register("tipo_garantia_id")}
                                        defaultValue={isEditing ? pieza?.tipo_garantia_id : 1}
                                        disabled={isVenta}
                                        onChange={handleChangeGarantia}>
                                        {tipoGarantias.map(op => <option key={'garantias-' + op.id} value={op.id}>{op.duracion}</option>)}
                                    </select>
                                }
                            </div>
                        </div>

                        <div className="d-flex flex-column flex-lg-row align-items-end">
                            <div className="form-group flex-grow-1 me-0 pe-lg-3">
                                <label htmlFor="precio_compra">KM motor</label>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    step="0.001"
                                    min={0}
                                    disabled={isVenta}
                                    {...register("km_motor")} 
                                    defaultValue={isEditing ? pieza?.km_motor : 0} />
                            </div>

                            <div className="form-group flex-grow-1">
                                <label htmlFor="precio_compra">Imágenes, <b>máximo 5</b> (opcional)</label>
                                <input 
                                    type="file" 
                                    className="form-control" 
                                    multiple
                                    accept={getAllowedExt('image')}
                                    // disabled={isVenta}
                                    disabled={true}
                                    {...register("imagenes")} 
                                    defaultValue={null} />
                            </div>
                        </div>

                        <div className="d-flex flex-column flex-lg-row align-items-end">
                            <div className="form-group flex-grow-1 me-0 pe-lg-3">
                                <label htmlFor="precio_original">Precio original</label>
                                <div className="position-relative">
                                    <input 
                                        type="number" 
                                        step="0.01"
                                        className="form-control" 
                                        min={0}
                                        {...register("precio_original", { required: true })} 
                                        defaultValue={isEditing ? pieza?.precio_original : 0} />
                                    <FontAwesomeIcon icon={faEuroSign} color="#215732" className="icon" />
                                </div>
                                
                                <div className="form-invalid">
                                    {errors.precio_original?.message && "Precio original es obligatorio y tiene que ser más de 0"}
                                </div>
                            </div>

                            <div className="form-group flex-grow-1">
                                <label htmlFor="precio_compra">Precio compra</label>
                                <div className="position-relative">
                                    <input 
                                        type="number" 
                                        step="0.01"
                                        className="form-control" 
                                        min={0}
                                        disabled={isVenta && !(currentUser?.rol_id === 1 || currentUser?.rol_id === 4)}
                                        onKeyDown={isVenta ? null : handleCalcularPrecios}
                                        {...register("precio_compra", { required: true })} 
                                        defaultValue={isEditing ? pieza?.precio_compra : 0} />
                                    <FontAwesomeIcon icon={faEuroSign} color="#215732" className="icon" />
                                </div>
                                
                                <div className="form-invalid">
                                    {errors.precio_compra?.message && "Precio compra es obligatorio y tiene que ser más de 0"}
                                </div>
                            </div>
                        </div>
                    
                        <div className="d-flex flex-column flex-lg-row align-items-end">
                            <div className="form-group flex-grow-1 me-0 pe-lg-3">
                                <label htmlFor="precio_venta">Precio venta (sin IVA)</label>
                                <div className="position-relative">
                                    <input 
                                        type="number" 
                                        step="0.01"
                                        className="form-control" 
                                        min={0}
                                        {...register("precio_venta", { required: true })} 
                                        defaultValue={isEditing ? pieza?.precio_venta : 0} />
                                    <FontAwesomeIcon icon={faEuroSign} color="#215732" className="icon" />
                                </div>
                                
                                
                                <div className="form-invalid">
                                    {errors.precio_venta?.message && "Precio venta es obligatorio y tiene que ser más de 0"}
                                </div>
                            </div>

                            <div className="form-group flex-grow-1">
                                <label htmlFor="precio_transporte">Transporte (sin IVA)</label>
                                <div className="position-relative">
                                    <input 
                                        type="number" 
                                        step="0.01"
                                        className="form-control" 
                                        disabled={isVenta}
                                        {...register("precio_transporte", { required: true })} 
                                        min={0}
                                        defaultValue={isEditing ? (pieza?.precio_transporte !== null ? pieza?.precio_transporte : 0) : 0} 
                                        onKeyUp={(e) => {
                                            e.preventDefault();
                                            clearTimeOut();
                                            startTimeOut(e);
                                        }}
                                    />
                                    <FontAwesomeIcon icon={faEuroSign} color="#215732" className="icon" />
                                </div>

                                <div className="form-invalid">
                                    {errors.precio_transporte?.message && "Precio transporte es obligatorio y tiene que ser positivo"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-100 d-flex align-items-center">
                    <div className="form-group flex-grow-1">
                        <label htmlFor="observaciones">Observaciones</label>
                        <textarea
                            disabled={isVenta}
                            {...register("observaciones")}
                            rows={5}
                            defaultValue={isEditing ? pieza?.observaciones : ''} >
                        </textarea>
                    </div>
                </div>

                <div className="d-flex align-items-center align-self-end">
                    <button 
                        type="submit" 
                        className="btn btn-primary align-self-end"
                        disabled={!servicioEnvio ? true : false}
                    >Guardar</button>
                    { cancel &&  <button type="button" className="btn btn-default align-self-end ms-3" onClick={handleCancel}>Cancelar</button> }
                </div>
            </form>
        </div>
    );
}