import { useForm } from "react-hook-form";
import { MenuItem, Select } from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

export default function EditForm({ venta, onSubmitEdit }) {
  /** Variables **/
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      codigo_pedido: venta.codigo_pedido,
      plataforma: venta.plataforma.id,
    },
  });
  const plataformas = useSelector((state) => {
    return state.plataformas;
  });

  /** States **/
  const [selectedPlatform, setSelectedPlatform] = useState(venta.plataforma.id);

  /** Methods **/
  const handleSubmitEdit = (data, e) => {
    e.preventDefault();
    onSubmitEdit({
        id: venta.id,
        codigo_pedido: data.codigo_pedido,
        plataforma_id: data.plataforma,
    });
  };

  /** Render **/
  return (
    <form className="form w-100" onSubmit={handleSubmit(handleSubmitEdit)}>
      <div className="form-group">
        <label>Número pedido</label>
        <input
          {...register("codigo_pedido", { required: true })}
          type="text"
          name="codigo_pedido"
          className="form-control"
        />
        <div className="form-invalid">
          {errors.codigo_pedido?.type === "required" &&
            "Número de pedido es obligatorio"}
        </div>
      </div>

      <div className="form-group">
        <label>Plataforma</label>
        <Select
          {...register("plataforma")}
          size="small"
          name="plataforma"
          onChange={(e) => setSelectedPlatform(e.target.value)}
          value={selectedPlatform}
        >
          {plataformas.map((item) => (
            <MenuItem key={`filter-plataforma-${item.id}`} value={item.id}>
              {item.nombre}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div className="d-flex align-items-center justify-content-end w-100 mt-3">
        <button type="submit" className="btn btn-primary">
          Guardar
        </button>
      </div>
    </form>
  );
}
