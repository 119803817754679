import { useState, useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function IncidenciaForm({
    venta,
    onSubmitIncidencia, 
}) {

    const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm({
        defaultValues: {
            piezas: [],
            tipo_incidencia_id: 0,
            subtipo_incidencia_id: '',
            culpa: '',
        }
    });
    const [proveedoresPiezas, setProveedoresPiezas] = useState([]);
    const tiposIncidencia = useSelector(state => {
        const { tipo_incidencias } = state;
        return tipo_incidencias;
    });
    const subtiposIncidencia = useSelector((state) => {
        return state.subtipos_incidencia?.filter((subtipo) => subtipo.active);
    });
    const posiblesCulpables = ['Proveedor', 'Cliente', 'Recomotor', 'Transporte proveedor', 'Transporte propio', 'Atencion comercial'];

    useEffect(() => {
        const provPiezas = venta.piezas?.reduce((acc, cur) => {
            const index = acc.findIndex(f => f.proveedor.id === cur.proveedor.id);

            if(index !== -1) {
                acc[index].piezas.push(cur);
            } else {
                acc.push({proveedor: cur.proveedor, piezas: [cur]})
            }

            return acc;
        }, [])
        setProveedoresPiezas(provPiezas);
    }, [venta]);

    const handleChangeCulpa = (e) => { setValue('culpa', e.target.value); }

    const handleChangeTipoIncidencia = (e) => { 
        setValue('tipo_incidencia_id', e.target.value); 
        setValue('subtipo_incidencia_id', '');
    }

    const handleChangeSubtipoIncidencia = (e) => { setValue('subtipo_incidencia_id', e.target.value); }

    const handleSubmitIncidencia = async (data, e) => {
        e.preventDefault();
        let incidencias = [];
           
        if(data.piezas && data.piezas.length > 0) {
            data.piezas.forEach((piezaId) => {
                const pieza = venta.piezas.find(p => p.id === parseInt(piezaId));

                let incidenciaAux = {
                    'tipo_incidencia_id': parseInt(data.tipo_incidencia_id),
                    'subtipo_incidencia_id': parseInt(data.subtipo_incidencia_id),
                    'proveedor_contacto': '-',
                    'venta_id': venta.id,
                    'entity_id': venta.entity_id,
                    'proveedor_id': pieza.proveedor.id,
                    'comentario': data.comentario,
                    'culpa': data.culpa,
                    'perdida': null,
                    'pieza_id': piezaId
                };
    
                incidencias.push(incidenciaAux);
            })

            onSubmitIncidencia(incidencias);
        } else {
            toast.warning('Tienes que elegir al menos una pieza!!');
            return;
        }
    };
 
    return (
        <div className="w-100 d-flex flex-column justify-content-center">
            <form className="form mb-4" onSubmit={handleSubmit(handleSubmitIncidencia)}>
                <div className="w-100 d-flex flex-column">
                    <div className="w-100">
                        <div className="w-100">
                            <div className="form-group">
                                <label>¿Quién se responsabiliza?</label>
                                <select 
                                    {...register("culpa", {required: true})}
                                    defaultValue={''}
                                    onChange={handleChangeCulpa}>
                                    {posiblesCulpables.map(op => <option key={'tipo-incidencias-' + op.replace(' ', '-').toLocaleLowerCase()} value={op.replace(' ', '-').toLocaleLowerCase()}>{op}</option>)}
                                </select>

                                <div className="form-invalid mb-3">
                                    {errors.culpa?.type === 'required' && "La responsabilidad es obligatoria"}
                                </div>
                            </div>
                            <div className="w-100">
                                { tiposIncidencia.map(tipo => {
                                    return (
                                        <div className="form-group radiobutton-group" key={'tipo_incidencia' + tipo.id}>
                                            <label htmlFor="tipo_incidencia" style={{fontWeight: 'normal'}} 
                                                className="radiobutton-group-text d-flex flex-wrap align-items-center">
                                                <input
                                                    {...register("tipo_incidencia_id", { required: true })}
                                                    type="radio"
                                                    name="tipo_incidencia_id"
                                                    value={tipo.id}
                                                    id="tipo_incidencia_id"
                                                    onChange={handleChangeTipoIncidencia}
                                                /> &nbsp;
                                                <b className="mx-1">{tipo.nombre}:</b> {tipo.descripcion}
                                            </label>

                                            { (watch('tipo_incidencia_id') == tipo.id) && 
                                                subtiposIncidencia && 
                                                (subtiposIncidencia.length > 0) && 
                                                (subtiposIncidencia.some(subtipo => subtipo.tipo_incidencia_id === tipo.id)) && (
                                                    <div style={{marginLeft: "1rem"}}>
                                                        <select
                                                            {...register("subtipo_incidencia_id")}
                                                            className="w-100"
                                                            defaultValue={''}
                                                            onChange={handleChangeSubtipoIncidencia}
                                                        >
                                                            <option value={''}>
                                                                No especificar
                                                            </option>
                                                            {subtiposIncidencia
                                                                .filter((subtipo) => subtipo.tipo_incidencia_id === tipo.id)
                                                                .map((subtipoIncidencia) => (
                                                                    <option
                                                                    key={"tipo-incidencias-" + subtipoIncidencia.id}
                                                                    value={subtipoIncidencia.id}
                                                                    >
                                                                    {subtipoIncidencia.nombre}
                                                                    </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                            )}
                                        </div> 
                                    )})
                                }

                                <div className="form-invalid mb-3">
                                    {errors.tipo_incidencia_id?.type === 'required' && "El tipo de incidencia es obligatorio"}
                                </div>
                            </div>
                        </div>
                    </div>

                    { proveedoresPiezas.map((proveedor, i) => {
                            return (
                                <div className="card-item proveedor-card d-flex align-items-center justify-content-between" key={'proveedor'+proveedor.proveedor.id}>
                                    <div className="w-100" key={proveedor.proveedor.id}>
                                        <div className="w-100 proveedor-card-info d-flex mb-2">
                                            <p className="text-uppercase"><b>{proveedor.proveedor.nombre}</b></p>
                                            <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                                                <div className="d-flex align-items-center">
                                                    { proveedor.proveedor.telefono && 
                                                        <p className="me-3"><FontAwesomeIcon icon={faPhone} size="1x" color="#215732"></FontAwesomeIcon> {proveedor.proveedor.telefono}</p>
                                                    }
                                                    { proveedor.proveedor.email && 
                                                        <p><FontAwesomeIcon icon={faEnvelope} size="1x" color="#215732"></FontAwesomeIcon> {proveedor.proveedor.email}</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    

                                        { proveedor.piezas.length > 0 && proveedor.piezas.map((pieza, j) => {
                                                return (
                                                    <div className="d-flex pieza mb-3" key={'pieza-'+ i +'-'+j}>
                                                        <div className="d-flex align-items-center">
                                                            <input type="checkbox"
                                                                name={`piezas[]`}
                                                                {...register(`piezas[]`)}
                                                                value={pieza.id}
                                                                className="me-3" />
                                                            
                                                            <FontAwesomeIcon icon={faCogs} size="1x" color="#215732"></FontAwesomeIcon>
                                                            <div className="d-flex flex-column">
                                                                <p className="ms-3">{pieza.nombre}</p>
                                                                { pieza.observaciones ? <p className="ms-3"> <b>Observaciones:</b>{pieza.observaciones}</p> : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="d-flex align-items-center align-self-end">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    );
}