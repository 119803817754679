// LOAD ALL THE READ ONLY DATA
import { getSubtiposIncidencia } from "../views/Dashboard/components/shared/services/subtipos_incidencia";
import {
  makeFetchingReducer,
  makeAsyncTypes,
  asyncMac,
  makeSetReducer,
} from "./utils";

const asyncTipos = makeAsyncTypes("subtipos_incidencia");

const [setPending, setFullFilled, setError] = asyncMac(asyncTipos);

export const subtiposIncidenciaFullfilledReducer = makeSetReducer([
  "subtipos_incidencia/fullfilled",
]);

export const fetchingReducer = makeFetchingReducer([
  "subtipos_incidencia/pending",
  "subtipos_incidencia/fullfilled",
  "subtipos_incidencia/rejected",
]);

export const fetchSubtiposIncidencia = () => async (dispatch) => {
  dispatch(setPending());

  try {
    const response = await getSubtiposIncidencia();
    const data = await response.data;
    dispatch(setFullFilled(data));
  } catch (e) {
    dispatch(setError(e.message));
  }
};
