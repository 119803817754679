import { useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import VirtualizedAutocompletePiezas from "../../../shared/components/AutocompletePiezas";
import { getAllowedExt } from "../../../shared/helpers/functionalities";

export default function DevolucionMercanciaForm({ onSubmitDevolucion }) {
  /** Variables **/
  const MAX_LENGTH = 3;
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      damaged: 0,
      desperfectoFiles: null,
      etiquetaFile: null,
      observaciones: "",
      paqueteFile: null,
      pieza: null,
      piezaFile: null,
      pieza_id: null,
    },
    mode: "onChange",
  });

  /** Lifecycle **/
  useEffect(() => {}, []);

  /** Methods **/
  const onSubmit = async (data, e) => {
    e.preventDefault();

    // HACER UNA COPIA SUPERFICIAL DE LOS DATOS PARA EVITAR MUTAR EL OBJETO ORIGINAL
    const cleanedData = { ...data };
    cleanedData.id_referencia_plataforma =
      cleanedData.pieza.id_referencia_plataforma;
    delete cleanedData.pieza;
    delete cleanedData.damaged;

    const { piezaFile, paqueteFile, etiquetaFile, desperfectoFiles } =
      cleanedData;

    // ASEGURARSE DE QUE NO SE SUBAN MÁS DE UNA IMAGEN POR TIPO DE ARCHIVO
    const hasTooManyFiles =
      piezaFile?.length > 1 ||
      paqueteFile?.length > 1 ||
      etiquetaFile?.length > 1;
    if (hasTooManyFiles) {
      toast.warning(
        `No se pueden subir más de 1 imagen por archivo (pieza, paquete, etiqueta)!`
      );
      return;
    }

    // ASEGUARSE DE QUE NO SE SUBAN MÁS DE 3 IMÁGENES DE DESPERFECT
    if (desperfectoFiles?.length > MAX_LENGTH) {
      toast.warning(
        `No se pueden subir más de ${MAX_LENGTH} imágenes de desperfectos!`
      );
      return;
    }

    // SI NO HAY ARCHIVOS PARA SUBIR, SIMPLEMENTE ENVIAR LOS DATOS
    if (
      !desperfectoFiles?.length &&
      !piezaFile?.length &&
      !paqueteFile?.length &&
      !etiquetaFile?.length
    ) {
      return onSubmitDevolucion(cleanedData);
    }

    // AÑADIR LOS DATOS QUE NO SON ARCHIVOS AL FORMDATA
    const dataAux = new FormData();
    Object.entries(cleanedData).forEach(([key, value]) => {
      if (
        ![
          "piezaFile",
          "paqueteFile",
          "etiquetaFile",
          "desperfectoFiles",
        ].includes(key)
      ) {
        dataAux.append(key, value);
      }
    });

    // AÑADIR LOS ARCHIVOS AL FORMDATA
    piezaFile?.length && dataAux.append(`piezaFile`, piezaFile[0]);
    paqueteFile?.length && dataAux.append(`paqueteFile`, paqueteFile[0]);
    etiquetaFile?.length && dataAux.append(`etiquetaFile`, etiquetaFile[0]);
    if (desperfectoFiles?.length) {
      for (let i = 0; i < desperfectoFiles?.length; i++) {
        dataAux.append(`desperfectoFiles[${i}]`, desperfectoFiles[i]);
      }
    }

    // ENVIAR LOS DATOS
    onSubmitDevolucion(dataAux);
  };
  const handleChangePieza = (changedPieza) => {
    setValue("pieza", changedPieza);
    setValue("pieza_id", changedPieza?.id);
  };

  /** Render **/
  return (
    <div className="w-100">
      <div className="w-100 d-flex flex-wrap flex-md-nowrap">
        <form className="form col-12 mb-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="w-100 d-flex flex-column">
            <div className="form-group">
              <label>Pieza</label>
              <input
                type="hidden"
                {...register("pieza_id", { required: true })}
              />

              <VirtualizedAutocompletePiezas
                id={"pieza"}
                control={control}
                getValues={getValues}
                labels={["id_referencia_plataforma", "nombre"]}
                defaultValue={null}
                onChange={handleChangePieza}
              />

              <div className="form-invalid">
                {errors.pieza_id?.type === "required" &&
                  "La pieza es obligatoria"}
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="observaciones">Observaciones</label>
              <textarea
                {...register("observaciones", { required: true })}
                defaultValue={""}
              />
              <div className="form-invalid">
                {errors.observaciones?.type === "required" &&
                  "La observación es obligatoria"}
              </div>
            </div>

            <div className="form-group flex-grow-1">
              <label htmlFor="piezaFile">Pieza</label>
              <input
                type="file"
                className="form-control"
                multiple
                accept={getAllowedExt("image")}
                {...register("piezaFile", { required: true })}
                defaultValue={null}
              />
              <div className="form-invalid">
                {errors.piezaFile?.type === "required" &&
                  "La imagen de la pieza es obligatoria"}
              </div>
            </div>

            <div className="form-group flex-grow-1">
              <label htmlFor="paqueteFile">Paquete</label>
              <input
                type="file"
                className="form-control"
                multiple
                accept={getAllowedExt("image")}
                {...register("paqueteFile", { required: true })}
                defaultValue={null}
              />
              <div className="form-invalid">
                {errors.paqueteFile?.type === "required" &&
                  "La imagen del paquete es obligatoria"}
              </div>
            </div>

            <div className="form-group flex-grow-1">
              <label htmlFor="etiquetaFile">Etiqueta</label>
              <input
                type="file"
                className="form-control"
                multiple
                accept={getAllowedExt("image")}
                {...register("etiquetaFile", { required: true })}
                defaultValue={null}
              />
              <div className="form-invalid">
                {errors.etiquetaFile?.type === "required" &&
                  "La imagen de la etiqueta es obligatoria"}
              </div>
            </div>

            <div className="form-group">
              <label>
                Tiene algún desperfecto respecto a las imágenes originales?
              </label>

              <div className="d-flex align-items-center">
                <input
                  type="checkbox"
                  {...register("damaged")}
                  name={`damaged`}
                  defaultChecked={false}
                />
                <label className="m-0 ms-2">Si</label>
              </div>
            </div>

            {watch("damaged") ? (
              <div className="form-group flex-grow-1">
                <label htmlFor="desperfectoFiles">
                  Imágenes, <b>máximo 3</b>
                </label>
                <input
                  type="file"
                  className="form-control"
                  multiple
                  accept={getAllowedExt("image")}
                  {...register("desperfectoFiles", { required: true })}
                  defaultValue={null}
                />
                <div className="form-invalid">
                  {errors.desperfectoFiles?.type === "required" &&
                    "Las imagenes de los desperfectos son obligatorias"}
                </div>
              </div>
            ) : null}
          </div>

          <div className="d-flex align-content-center align-self-end">
            <button type="submit" className="btn btn-primary">
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
