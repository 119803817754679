import { useState, useEffect } from "react";
import React from "react";
import LightBoxImages from "../../../shared/components/LightBoxImages";

export default function Detail({
    devolucion
}) {
    const [openImage, setOpenImage] = useState(false);
    const [imagesSRCs, setImagesSRCs] = useState([]);
    const [imageIndex, setImageIndex] = useState(0);
 
    useEffect(() => {
       const imgSRCs = devolucion.imagenes?.map((imagen) => {
        return `data:image/jpeg;base64,${imagen.img}`;
       })
       setImagesSRCs(imgSRCs);
    }, [devolucion]);

    const handleOpenLightBox = (index) => {
        setImageIndex(index);
        setOpenImage(true);
    }

    const handleCloseImages = () => {
        setOpenImage(false);
        setImageIndex(null);
    }

    return (
        <div className="w-100 d-flex flex-column">
            <div className="d-flex flex-column">
                <small>
                    #<b className="me-2">{devolucion.pieza.id_referencia_plataforma}</b> 
                    {devolucion.pieza.nombre}
                </small>
            </div>

            <div className="mt-3">
                <label>Observaciones:</label>
                <p>{devolucion.observaciones}</p>
            </div>

            <div className="d-flex flex-column mt-3">
                <label>Imágenes</label>

                <div className="w-100 d-flex flex-wrap align-items-center">
                    { devolucion.imagenes?.length > 0 ? devolucion.imagenes?.map(({img}, i) => {
                        return (
                            <img 
                                key={"Imagen " + (i+1)} 
                                src={`data:image/jpeg;base64,${img}`}
                                alt={"Imagen " + (i+1)} 
                                className="mb-4 me-4"
                                width={'200px'}
                                height={'200px'}
                                style={{cursor: 'pointer', objectFit: 'cover'}}
                                onClick={() => handleOpenLightBox(i)}
                            />
                        )
                    }) : <p>No hay imágenes.</p>
                    }
                </div>
            </div>

            {devolucion.comentario_almacen && 
                <div className="mt-3">
                    <label>Comentario para almacén:</label>
                    <p>{devolucion.comentario_almacen}</p>
                </div>
            }

            { (openImage) &&
                <LightBoxImages
                    images={imagesSRCs}
                    startIndex={imageIndex}
                    pieza={"Devolución mercancía"}
                    onClose={handleCloseImages}
                />
            }
        </div>
    )
}