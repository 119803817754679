import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { getAllowedExt } from '../../../../../shared/helpers/functionalities';

export default function FinalizarForm({
    onSubmitFinalizar
}) {
    const [isOpenApartarParaReparar, setIsOpenApartarParaReparar] = useState(false)
    const [isOpenEnviarAlCliente, setIsOpenEnviarAlCliente] = useState(false)
    const { register, handleSubmit, setValue } = useForm({
        defaultValues: {
           estado_pieza_id: null
        }
    });
    const estadosPiezas = useSelector(state => {
        const { estados_piezas } = state;
        return estados_piezas;
    });

    const handleChangeEstado = (e) => {
        const value = parseInt(e.target.value);
        setValue('estado_pieza_id', value);

        isOpenApartarParaReparar && setIsOpenApartarParaReparar(false)
        isOpenEnviarAlCliente && setIsOpenEnviarAlCliente(false)
        if(e.target.value === '5') return setIsOpenApartarParaReparar(true)
        if(e.target.value === '3') return setIsOpenEnviarAlCliente(true)
    }

    const handleSubmitFinalizar = async (data, e) => {
        e.preventDefault();
        onSubmitFinalizar(data);
    };
 
    return (
        <div className="w-100 d-flex flex-column justify-content-center">
            <form className="form" onSubmit={handleSubmit(handleSubmitFinalizar)}>
                <div className="w-100 d-flex">
                    <div className="form-group">
                        <label htmlFor="rol">Estado de la pieza:</label>
                        { (estadosPiezas && estadosPiezas.length > 0) &&
                            <select 
                                {...register("estado_pieza_id", { required: true })}
                                defaultValue={null}
                                onChange={handleChangeEstado}>
                                <option value={-1}>Selecciona un estado...</option>
                                {
                                    estadosPiezas.map(op => <option key={'estados-' + op.id} value={op.id}>{op.nombre}</option>)
                                }
                            </select>
                        }
                    </div> 
                </div>

                {isOpenApartarParaReparar && <div className="form-group">
                    <label htmlFor="comentario_almacen">Comentario para almacén:</label>
                    <textarea {...register("comentario_almacen", { required: true })} />
                </div>}
                
                {isOpenEnviarAlCliente && <div className="form-group">
                    <label htmlFor="etiqueta">Etiqueta de envío:</label>
                    <input 
                        type="file" 
                        className="form-control" 
                        accept={getAllowedExt('pdf')}
                        {...register("etiqueta", { maxLength: 255 })} 
                    />
                </div>}

                <div className="d-flex align-items-center align-self-end">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    );
}